import React from 'react';

import Loading from '/src/components/shared/loading';

function Creating() {
  return (
    <div className="flex flex-col items-center">
      <h1 className="modal-header color--dark-gray mb-3">Creating game...</h1>
      <div className="my-4">
        <Loading />
      </div>
    </div>
  );
}

export default Creating;
