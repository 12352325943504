import React, { useReducer } from 'react';

import GameStateContext from '/src/state/context';
import gameStateReducer from '/src/state/reducer';

type PROPS = { children: React.ReactNode };

function GameStateProvider({ children }: PROPS) {
  const [state, dispatch] = useReducer(gameStateReducer, {});

  function actionWrapper(action: Function) {
    return (...args: any[]) =>
      action.apply(null, [...args, { state, dispatch }]);
  }

  return (
    <GameStateContext.Provider value={{ state, dispatch, actionWrapper }}>
      {children}
    </GameStateContext.Provider>
  );
}

export default GameStateProvider;
