import React from 'react';

import {
  isHotTopicSuperlative,
  SUPERLATIVE
} from '/src/utilities/superlatives';

type PROPS = {
  superlative: SUPERLATIVE;
};

function Superlative({ superlative }: PROPS) {
  const { header, subheader, recipient, footer } = superlative;

  return (
    <div className="flex-grow w-full flex flex-col justify-between items-center">
      <div className="text-center">
        <h1 className="modal-header color--dark-gray mb-2">{header}</h1>
        <h2 className="font-bold text-lg">{subheader}</h2>
      </div>
      <div className="text-center">
        {isHotTopicSuperlative(superlative) && (
          <span className="block text-3xl font-bold color--dark-gray">
            "{superlative.topic}"
          </span>
        )}
        <span className="text-3xl font-bold color--primary-dark">
          {isHotTopicSuperlative(superlative) ? '-' : ''}
          {recipient}
        </span>
      </div>
      <span className="text-lg text-center">{footer}</span>
    </div>
  );
}

export default Superlative;
