import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  connectDatabaseEmulator,
  get,
  ref,
  onValue,
  update,
  push,
  set,
  remove,
  off
} from 'firebase/database';
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from 'firebase/functions';

import { PLAYER, GameMode } from '/src/state/types';

const config = {
  apiKey: 'AIzaSyCRM0-Xupt1ZvlTTt-FPYY6bieCnxTgMhI',
  authDomain: 'top-four-cca25.firebaseapp.com',
  databaseURL: 'https://top-four-cca25.firebaseio.com',
  projectId: 'top-four-cca25',
  storageBucket: 'top-four-cca25.appspot.com',
  messagingSenderId: '120019969623',
  appId: '1:120019969623:web:6d6ba9a3d0834b0259e512'
};

initializeApp(config);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
  connectDatabaseEmulator(getDatabase(), 'localhost', 9000);
}

function isDev() {
  return ['development', 'staging'].includes(process.env.NODE_ENV || '');
}

const FUNCTIONS = {
  END_GAME: isDev() ? 'endGameDev' : 'endGame',
  JOIN_GAME: isDev() ? 'joinGameDev' : 'joinGame',
  START_GAME: isDev() ? 'startGameDev' : 'startGame',
  START_ROUND: isDev() ? 'startRoundDev' : 'startRound'
};

export async function getTopicPacksService() {
  const topicPacks = await get(getRef('/topicPacks_v2'));

  return topicPacks.val();
}

export async function getWhatsNewService() {
  const whatsNew = await get(getRef('/whats_new'));

  return whatsNew.val();
}

export async function createGameService(
  topicPackUids: string[],
  numRounds: number,
  name: string,
  referrer: string,
  mode: GameMode
) {
  const createGame = httpsCallable(getFunctions(), FUNCTIONS.START_GAME);

  const response = await createGame({
    topicPacks: topicPackUids.length > 0 ? topicPackUids : false,
    numRounds,
    name,
    referrer,
    mode
  });

  return response.data;
}

export async function joinGameService(
  gameId: string,
  name: string,
  rejoiner: boolean
) {
  const joinGame = httpsCallable(getFunctions(), FUNCTIONS.JOIN_GAME);

  const response = await joinGame({
    gameId,
    name,
    rejoiner
  });

  return response.data;
}

export async function endGameService(gameUid: string) {
  const endGame = httpsCallable(getFunctions(), FUNCTIONS.END_GAME);

  return await endGame({ gameUid });
}

let subscribedGameUid: string | null = null;
export function subscribeToGameUpdatesService(
  gameUid: string,
  on: (game: any) => void
) {
  unsubscribeFromGameUpdatesService();

  onValue(getRef(`/games/${gameUid}`), snapshot => on(snapshot.val()));
}

export function unsubscribeFromGameUpdatesService() {
  if (subscribedGameUid) {
    off(getRef(`/games/${subscribedGameUid}`));

    subscribedGameUid = null;
  }
}

// TODO handle unsub
export function subscribeToPlayerRanksService(
  gameUid: string,
  playerUid: string,
  on: (ranks: { [key: string]: number }) => void
) {
  onValue(getRef(`/playerRanks/${gameUid}/${playerUid}`), snapshot =>
    on(snapshot.val())
  );
}

export function startRoundService(gameUid: string, playerUid: string) {
  const startRound = httpsCallable(getFunctions(), FUNCTIONS.START_ROUND);

  startRound({ gameUid, playerUid });
}

export function updateGameService(
  game: { [key: string]: any },
  gameUid: string
) {
  update(getRef(`/games/${gameUid}`), game);
}

export function addTopicService(
  topic: string,
  playerUid: string,
  gameUid: string
) {
  push(getRef(`/games/${gameUid}/topics`), {
    topic,
    playerUid,
    status: 'available',
    rank: -1
  });
}

export function deleteTopicService(topicUid: string, gameUid: string) {
  remove(getRef(`/games/${gameUid}/topics/${topicUid}`));
}

export function setPlayerActiveService(
  playerUid: string,
  active: boolean,
  gameUid: string
) {
  update(getRef(`/games/${gameUid}/players/${playerUid}`), { active });
}

export function setPlayerNameService(
  playerUid: string,
  name: string,
  gameUid: string
) {
  update(getRef(`/games/${gameUid}/players/${playerUid}`), { name });
}

export async function lockInService(
  gameUid: string,
  guesses: { [key: string]: number | string },
  player: PLAYER,
  playerUid: string
) {
  update(getRef(`/games/${gameUid}/players/${playerUid}`), player);

  update(getRef(`/games/${gameUid}/guesses/${playerUid}`), guesses);
}

export async function updatePlayerRanksService(
  gameUid: string,
  playerUid: string,
  guesses: { [key: string]: number }
) {
  set(getRef(`/playerRanks/${gameUid}/${playerUid}`), guesses);
}

export function logErrorMessageService(message: string, env: string) {
  const date = new Date();

  const yyyy = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();

  const mm = `${m > 9 ? '' : '0'}${m}`;
  const dd = `${d > 9 ? '' : '0'}${d}`;

  const errorDate = `${yyyy}-${mm}-${dd}`;

  push(getRef(`/error/${env}/${errorDate}`), message);
}

export async function updatePlayerLikesService(
  gameUid: string,
  playerUid: string,
  likes: { [key: string]: boolean }
) {
  update(getRef(`/games/${gameUid}/likes/${playerUid}`), likes);
}

function getRef(path: string) {
  return ref(getDatabase(), path);
}
