import React from 'react';
import groupBy from 'lodash/groupBy';

import { WHATS_NEW_OBJ } from '/src/state/types';

interface WhatsNewProps {
  whatsNew: WHATS_NEW_OBJ;
}

function WhatsNew({ whatsNew }: WhatsNewProps) {
  const byDate = groupBy(
    Object.keys(whatsNew).map(uid => ({ uid, ...whatsNew[uid] })),
    'date'
  );

  const sorted = Object.keys(byDate)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .map(date => ({ date, items: byDate[date] }));

  return (
    <div>
      <h1 className="modal-header mb-4">Look What's New!</h1>
      {sorted.map(({ date, items }) => (
        <div key={date}>
          <span className="font-bold text-lg color--primary-dark">{date}</span>
          <ul className="list-disc list-outside mb-4 ml-4">
            {items.map(({ uid, desc }) => (
              <li key={uid}>{desc}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default WhatsNew;
