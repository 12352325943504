import React, { useState } from 'react';

import { isDesktop } from '/src/utilities/device';
import { toTurns } from '/src/utilities/game';
import { useGameState } from '/src/state';

import Button from '/src/components/shared/button';
import Logo from '/src/components/shared/logo';
import IconGear from '/src/components/shared/icons/gear';
import IconX from '/src/components/shared/icons/x';

import Social from '/src/components/shared/social';
import Settings from '/src/components/game/info/settings';

type PROPS = {
  setTopicsTop: (topicsTop: number) => void;
  showInfo: boolean;
  toggleShowInfo: () => void;
  topicsTop: number;
};

function Info({ setTopicsTop, showInfo, toggleShowInfo, topicsTop }: PROPS) {
  const [editingName, setEditingName] = useState(false);
  const { gameId, game } = useGameState();

  if (!game) return null;

  const [gameTurn, remainingTurns] = toTurns(game);

  function handleHeaderRef(el: HTMLDivElement) {
    if (el) {
      const { top, bottom } = el.getBoundingClientRect();
      const newTopicsTop = top + bottom;

      if (newTopicsTop !== topicsTop) {
        setTopicsTop(newTopicsTop);
      }
    }
  }

  return (
    <div className="flex flex-col items-center h-full bg-color--primary pb-16">
      <div
        className="flex justify-center items-center my-4 md:my-8 px-6 w-full"
        ref={handleHeaderRef}
      >
        <span className="color--white font-bold text-base">{gameId}</span>
        <span className="flex-grow md:flex-grow-0 md:mx-8">
          <Logo size={isDesktop() ? 'med' : 'tiny'} />
        </span>
        {showInfo && (
          <Button name="hide-info" onClick={toggleShowInfo} variant="icon">
            <IconX white />
          </Button>
        )}
        {!showInfo && (
          <Button name="show-info" onClick={toggleShowInfo} variant="icon">
            <IconGear white />
          </Button>
        )}
      </div>
      <div className="flex flex-col items-stretch h-full w-full px-6 space-y-4 overflow-hidden">
        <div className="flex justify-between items-center">
          <span className="color--white font-bold text-lg">
            Turns Played: {gameTurn}
          </span>
          <span className="color--white font-bold text-lg">
            Turns Remaining: {remainingTurns}
          </span>
        </div>
        <Settings editingName={editingName} setEditingName={setEditingName} />
        {!editingName && <Social />}
      </div>
    </div>
  );
}

export default Info;
