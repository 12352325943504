import React, { memo, useEffect, useState } from 'react';

import cx from '/src/utilities/cx';
import { GAME_STATE } from '/src/utilities/constants';
import { TOPIC } from '/src/state/types';
import { TURN_STATE } from '/src/utilities/game';
import { useAction } from '/src/state';
import {
  revealTopicAction,
  togglePlayerLikeAction
} from '/src/actions/in_game';

import Button from '/src/components/shared/button';

import Topic from '/src/components/game/topics/topic';
import TopicStatus from '/src/components/game/topics/topic_status';
import { rankableTopicState } from '/src/utilities/topics';
import IconDrag from '/src/components/shared/icons/drag';
import IconSmiley from '/src/components/shared/icons/smiley';

type PROPS = {
  activeTopics: TOPIC[];
  canLike: boolean;
  containerClasses?: string;
  localRanks: { [key: string]: number };
  guessesByTopic: { [key: string]: number[] };
  playerLikes: { [key: string]: boolean };
  tappable?: boolean;
  topic: TOPIC;
  topicClasses?: string;
  turnState: TURN_STATE;
};

function RankableTopic({
  activeTopics,
  canLike,
  containerClasses,
  guessesByTopic,
  localRanks,
  playerLikes,
  tappable,
  topic,
  topicClasses,
  turnState: { state, ranker, unlockedInPlayers }
}: PROPS) {
  const [showPercent, setShowPercent] = useState(false);

  const revealTopic = useAction(revealTopicAction);
  const togglePlayerLike = useAction(togglePlayerLikeAction);

  const localRank = localRanks[topic.uid || ''];
  const isLiked = playerLikes[topic.uid || ''];
  const { correctPercent, correctTopic, isCorrect, isRanked, isRevealable } =
    rankableTopicState(
      activeTopics,
      guessesByTopic,
      ranker,
      localRank,
      topic,
      state
    );

  useEffect(() => {
    if (!showPercent && state !== GAME_STATE.RANKING && isRanked) {
      if (ranker) {
        setTimeout(() => setShowPercent(true), 200);
      } else {
        setShowPercent(true);
      }
    }
  }, [state, isRanked, showPercent, ranker]);

  const containerWrapperClasses = cx(
    'flex-grow flex focus:outline-none overflow-hidden transition-all duration-200',
    {
      'opacity-50': isRevealable && unlockedInPlayers.length > 0,
      [containerClasses || '']: containerClasses
    }
  );

  const topicWrapperClasses = cx(
    'flex flex-grow justify-between items-center transition-all duration-200',
    {
      [topicClasses || '']: topicClasses
    }
  );

  const revealClasses = cx(
    'bg-color--primary flex justify-end items-center transition-all duration-200',
    {
      'max-w-0 invisible': !isRevealable,
      'max-w-xl': isRevealable
    }
  );

  return (
    <div className={containerWrapperClasses}>
      <div className={topicWrapperClasses}>
        <div className="flex-grow flex mx-4 min-h-4">
          <Topic
            correctTopic={correctTopic?.topic}
            isCorrect={isCorrect}
            isRanker={ranker}
            showPercent={showPercent}
            tappable={tappable}
            topic={topic.topic}
          />
        </div>
        {state === GAME_STATE.RANKING && <IconDrag />}
        {canLike && state === GAME_STATE.LOCKED_IN && !isRanked && (
          <div className="mr-2">
            <Button
              name="like-topic"
              onClick={() => togglePlayerLike(topic.uid)}
              variant="icon"
            >
              <IconSmiley filled={isLiked} />
            </Button>
          </div>
        )}
        <TopicStatus
          correctPercent={correctPercent}
          isCorrect={isCorrect}
          isRanker={ranker}
          showPercent={showPercent}
        />
      </div>
      <div className={revealClasses}>
        <span className="mx-2">
          <Button
            disabled={unlockedInPlayers.length > 0}
            name="reveal"
            onClick={() => revealTopic(topic.uid)}
            variant="text-invert"
          >
            Reveal
          </Button>
        </span>
      </div>
    </div>
  );
}

export default memo(RankableTopic);
