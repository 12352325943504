import React from 'react';

import cx from '/src/utilities/cx';

import { useAction, useGameState } from '/src/state';
import { closeModalAction } from '/src/actions';

import Button from '/src/components/shared/button';

function Modal() {
  const { modalIsOpen, modalContent } = useGameState();
  const closeModal = useAction(closeModalAction);

  const wrapperClasses = cx(
    'fixed inset-0 p-6 transition-all duration-200 bg-black bg-opacity-25 md:flex md:items-center md:justify-center',
    {
      'visible opacity-1': modalIsOpen,
      'invisible opacity-0': !modalIsOpen
    }
  );

  return (
    <div className={wrapperClasses}>
      <div
        data-cy="modal"
        className="cntnr h-full flex flex-col md:w-3/4 md:h-3/5"
      >
        <div className="flex-grow m-4 overflow-auto">{modalContent}</div>
        <span className="self-end mb-2 mr-4">
          <Button
            name="close-modal"
            onClick={() => closeModal()}
            variant="text"
          >
            CLOSE
          </Button>
        </span>
      </div>
    </div>
  );
}

export default Modal;
