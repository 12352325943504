export function saveLocalGameState(
  gameId: string,
  gameUid: string,
  playerUid: string,
  playerName: string,
  startDate: string
) {
  if (typeof localStorage === 'undefined') return;

  localStorage.setItem('gameId', gameId);
  localStorage.setItem('gameUid', gameUid);
  localStorage.setItem('playerUid', playerUid);
  localStorage.setItem('playerName', playerName);
  localStorage.setItem('gameStartDate', startDate);
}

export function savePlayerName(playerName: string) {
  if (typeof localStorage === 'undefined') return;

  localStorage.setItem('playerName', playerName);
}

export function saveLocalTheme(theme: string) {
  if (typeof localStorage === 'undefined') return;

  localStorage.setItem('theme', theme);
}

export function getLocalGameState() {
  if (typeof localStorage === 'undefined') return {};

  // default undefined to be compatible to STATE
  return {
    gameId: localStorage.getItem('gameId') || undefined,
    gameUid: localStorage.getItem('gameUid') || undefined,
    playerUid: localStorage.getItem('playerUid') || undefined,
    playerName: localStorage.getItem('playerName') || undefined,
    gameStartDate: localStorage.getItem('gameStartDate') || undefined,
    theme: localStorage.getItem('theme') || undefined
  };
}

export function clearLocalGameState() {
  if (typeof localStorage === 'undefined') return;

  localStorage.removeItem('gameId');
  localStorage.removeItem('gameUid');
  localStorage.removeItem('playerUid');
  localStorage.removeItem('playerName');
  localStorage.removeItem('gameStartDate');
  localStorage.removeItem('theme');
}

export function saveWhatsNewUids(uids: string[]) {
  if (typeof localStorage === 'undefined') return;

  localStorage.setItem('whatsNewUids', JSON.stringify(uids));
}

export function getWhatsNewUids(): string[] {
  if (typeof localStorage === 'undefined') return [];

  return JSON.parse(localStorage.getItem('whatsNewUids') || JSON.stringify([]));
}
