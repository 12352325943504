import React from 'react';

import cx from '/src/utilities/cx';

type PROPS = {
  checked: boolean;
  pack: { uid: string; name: string };
  toggleTopicPackUid: (uid: string) => void;
};

function PackToggle({
  checked,
  pack: { uid, name },
  toggleTopicPackUid
}: PROPS) {
  const className = cx('border-2 b-color--primary rounded-full px-2 py-1', {
    'color--dark-gray': !checked,
    'bg-color--primary color--white font-bold': checked
  });

  return (
    <button className={className} onClick={() => toggleTopicPackUid(uid)}>
      {name}
    </button>
  );
}

export default PackToggle;
