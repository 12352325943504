import React, { UIEvent, useEffect, useRef, useState } from 'react';

import { useAction } from '/src/state';
import { TOPIC_PACK } from '/src/state/types';
import { openModalAction } from '/src/actions';
import { groupTopicPacks } from '/src/utilities/topics';
import cx from '/src/utilities/cx';

import Button from '/src/components/shared/button';
import Loading from '/src/components/shared/loading';
import IconCircleI from '/src/components/shared/icons/circle_i';
import IconPencil from '/src/components/shared/icons/pencil';
import IconDice from '/src/components/shared/icons/dice';

import PackToggle from '/src/components/create/pack_toggle';

type PROPS = {
  random: (uids: string[]) => void;
  toggleTopicPackUid: (uid: string) => void;
  topicPacks: TOPIC_PACK[];
  topicPackOwner?: string;
  topicPackUids: string[];
  writeOurOwn: () => void;
};

function Topics({
  random,
  toggleTopicPackUid,
  topicPacks,
  topicPackOwner,
  topicPackUids,
  writeOurOwn
}: PROPS) {
  const [scrolled, setScrolled] = useState(false);
  const [[, blurred], setBlur] = useState([false, false]);
  const scrollContainer = useRef(document.createElement('div'));
  const openModal = useAction(openModalAction);

  const groupedTopicPacks = groupTopicPacks(topicPacks, topicPackOwner);
  const standardPackUids = groupedTopicPacks
    .find(({ name }) => name === 'Classic')
    ?.packs.map(({ uid }) => uid);

  function handleScroll({ currentTarget }: UIEvent<HTMLDivElement>) {
    if (currentTarget.scrollTop > 0) {
      setScrolled(true);
      setBlur(([currBlurrable]) => [currBlurrable, false]);
    } else {
      setScrolled(false);
      setBlur(([currBlurrable]) => [currBlurrable, currBlurrable && true]);
    }
  }

  const scrollContainerClasses = cx('flex-grow overflow-y-auto', {
    'border-t': scrolled,
    'bottom-blur': blurred
  });

  useEffect(() => {
    if (
      scrollContainer.current.scrollHeight >
      scrollContainer.current.offsetHeight
    ) {
      setBlur([true, true]);
    }
  }, [topicPacks]);

  return (
    <div className="flex-grow w-full flex flex-col items-center overflow-y-hidden">
      <h1 className="modal-header color--dark-gray mb-4">
        Choose some topics!
      </h1>
      <div className="flex flex-col md:flex-row self-start md:self-center px-4 gap-4 mb-6">
        <Button
          name="write-our-own"
          normalCase
          onClick={writeOurOwn}
          variant="text"
        >
          <div className="flex items-center">
            <span className="mr-1">We'll write our own</span>
            <IconPencil />
          </div>
        </Button>
        <Button
          disabled={!standardPackUids}
          name="random"
          normalCase
          onClick={() => standardPackUids && random(standardPackUids)}
          variant="text"
        >
          <div className="flex items-center">
            <span className="mr-1">Surprise us</span>
            <IconDice />
          </div>
        </Button>
      </div>
      <div
        className={scrollContainerClasses}
        onScroll={handleScroll}
        ref={scrollContainer}
      >
        {groupedTopicPacks.length === 0 && (
          <div className="h-full flex flex-col justify-center items-center">
            <span className="font-bold text-lg color--primary-darkest">
              Loading topic packs...
            </span>
            <div className="mt-4">
              <Loading />
            </div>
          </div>
        )}
        {groupedTopicPacks.map(({ name, packs }) => (
          <div key={name} className="px-4">
            <h3 className="font-bold color--dark-gray mb-2">{name}</h3>
            <div className="flex flex-wrap gap-3 mb-6">
              {packs.map(pack => (
                <PackToggle
                  key={pack.uid}
                  checked={topicPackUids.includes(pack.uid)}
                  pack={pack}
                  toggleTopicPackUid={toggleTopicPackUid}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <span className="self-end">
        <Button
          onClick={() =>
            openModal(
              <>
                Topics are people, places and things that you’ll be asked to
                rank each turn.
                <br />
                <br />
                If you’re new to Top Four, we recommend selecting "Surprise us"
                to play with random topics we’ve provided.
                <br />
                <br />
                If you’ve played before, it’s a ton of fun to write your own or
                mix and match a few packs!
              </>
            )
          }
          name="coachmark"
          variant="icon"
        >
          <IconCircleI />
        </Button>
      </span>
    </div>
  );
}

export default Topics;
