import { useGameState } from '/src/state';

export const colors = {
  COLOR_ORANGE: '#ff5d28',
  COLOR_ORANGE_LIGHTEST: '#ffefe9',
  COLOR_BERRY: '#ff0e65',
  COLOR_BERRY_LIGHTEST: '#ffe7f0',
  COLOR_FUSCHIA: '#da00ed',
  COLOR_FUSCHIA_LIGHTEST: '#fbe5fd',
  COLOR_PURPLE: '#7e19ff',
  COLOR_PURPLE_LIGHTEST: '#f2e8ff',
  COLOR_INDIGO: '#3531ff',
  COLOR_INDIGO_LIGHTEST: '#ebeaff',
  COLOR_BLUE: '#0085ff',
  COLOR_BLUE_LIGHTEST: '#e5f3ff',
  COLOR_CYAN: '#00c2ff',
  COLOR_CYAN_LIGHTEST: '#e5f9ff',
  COLOR_TEAL: '#00cfcf',
  COLOR_TEAL_LIGHTEST: '#e5fdfd',
  COLOR_MINT: '#00e090',
  COLOR_MINT_LIGHTEST: '#e5fef4',
  COLOR_GREEN: '#3dc000',
  COLOR_GREEN_LIGHTEST: '#ecf9e5',
  COLOR_LIME: '#9acc0b',
  COLOR_LIME_LIGHTEST: '#f8fee4',
  COLOR_GOLDEN: '#ffa800',
  COLOR_GOLDEN_LIGHTEST: '#fff7db'
};

export function useThemeColor() {
  const { theme = 'orange' } = useGameState();

  return colors[`COLOR_${theme.toUpperCase()}`];
}

export function useLightestThemeColor() {
  const { theme = 'orange' } = useGameState();

  return colors[`COLOR_${theme.toUpperCase()}_LIGHTEST`];
}

export default useThemeColor;
