import React from 'react';

import useThemeColor from '/src/components/shared/use_theme_color';
import PROPS from './props';

function IconDrag({ white }: PROPS) {
  const themeColor = useThemeColor();

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 13.5C15.8284 13.5 16.5 12.8284 16.5 12C16.5 11.1716 15.8284 10.5 15 10.5C14.1716 10.5 13.5 11.1716 13.5 12C13.5 12.8284 14.1716 13.5 15 13.5Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M15 25.5C15.8284 25.5 16.5 24.8284 16.5 24C16.5 23.1716 15.8284 22.5 15 22.5C14.1716 22.5 13.5 23.1716 13.5 24C13.5 24.8284 14.1716 25.5 15 25.5Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M21 13.5C21.8284 13.5 22.5 12.8284 22.5 12C22.5 11.1716 21.8284 10.5 21 10.5C20.1716 10.5 19.5 11.1716 19.5 12C19.5 12.8284 20.1716 13.5 21 13.5Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M21 25.5C21.8284 25.5 22.5 24.8284 22.5 24C22.5 23.1716 21.8284 22.5 21 22.5C20.1716 22.5 19.5 23.1716 19.5 24C19.5 24.8284 20.1716 25.5 21 25.5Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M21 19.5C21.8284 19.5 22.5 18.8284 22.5 18C22.5 17.1716 21.8284 16.5 21 16.5C20.1716 16.5 19.5 17.1716 19.5 18C19.5 18.8284 20.1716 19.5 21 19.5Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M15 19.5C15.8284 19.5 16.5 18.8284 16.5 18C16.5 17.1716 15.8284 16.5 15 16.5C14.1716 16.5 13.5 17.1716 13.5 18C13.5 18.8284 14.1716 19.5 15 19.5Z"
        fill={white ? '#fff' : themeColor}
      />
    </svg>
  );
}

export default IconDrag;
