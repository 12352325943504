import React from 'react';

import cx from '/src/utilities/cx';

import Button from '/src/components/shared/button';
import TextInput from '/src/components/shared/text_input';

type PROPS = {
  focused: boolean;
  loading: boolean;
  name: string;
  onBlur: () => void;
  onFocus: () => void;
  onStartGame: () => void;
  setName: (name: string) => void;
};

function Name({
  focused,
  loading,
  name,
  onBlur,
  onFocus,
  onStartGame,
  setName
}: PROPS) {
  const disabled = loading || !name;
  const inputClasses = cx('mb-4', {
    'flex-grow': !focused && !name
  });

  const handleBlur = () => {
    setTimeout(onBlur, 200);
  };

  return (
    <div className="flex-grow w-full flex flex-col items-center">
      <h1 className="modal-header color--dark-gray mb-8">What's your name?</h1>
      <form
        autoComplete="off"
        className="flex flex-col flex-grow w-4/5 md:w-1/2"
      >
        <div className={inputClasses}>
          <TextInput
            label="Your name"
            name="name"
            onBlur={handleBlur}
            onChange={setName}
            onFocus={onFocus}
            placeholder=""
            value={name}
          />
        </div>

        <div className="mb-8">
          <Button
            disabled={disabled}
            fullWidth
            name="create-game"
            onClick={onStartGame}
            variant="primary"
          >
            Create Game
          </Button>
        </div>
      </form>
    </div>
  );
}

export { Name };
export default Name;
