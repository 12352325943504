import React from 'react';

import cx from '/src/utilities/cx';
import './style.scss';

type PROPS = {
  allowDefault?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  link?: boolean;
  name: string;
  normalCase?: boolean;
  onClick?: (event?: React.MouseEvent) => void;
  variant?:
    | 'primary'
    | 'primary-invert'
    | 'secondary'
    | 'secondary-invert'
    | 'text'
    | 'text-invert'
    | 'icon';
};

function Button({
  allowDefault = false,
  children,
  disabled,
  fullWidth,
  href,
  link,
  name,
  normalCase,
  onClick,
  variant = 'primary'
}: PROPS) {
  const Component = link ? 'a' : 'button';

  const classes = cx('btn', `btn--${variant}`, {
    'w-full': fullWidth,
    uppercase: !normalCase
  });

  function handleClick(event?: React.MouseEvent) {
    !allowDefault && event?.preventDefault();
    onClick && onClick();
  }

  return (
    <Component
      className={classes}
      data-testid={name}
      disabled={disabled}
      href={href}
      name={name}
      onClick={handleClick}
      target={link ? '_blank' : undefined}
    >
      {children}
    </Component>
  );
}

export default Button;
