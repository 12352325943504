import { useEffect, useState } from 'react';
import sampleSize from 'lodash/sampleSize';

import { useAction, useGameState } from '/src/state';
import { getTopicPacksAction } from '/src/actions';
import { TOPIC_PACK } from '/src/state/types';

function randomTopicFromTopicPacks(
  topicPacks: TOPIC_PACK[] | undefined,
  previousTopic: string | undefined
) {
  if (topicPacks) {
    const randomTopicsPack = topicPacks.find(
      ({ category }) => category === 'example'
    );

    if (randomTopicsPack) {
      const randomTopics = Object.keys(randomTopicsPack.topics)
        .map(topicUid => randomTopicsPack.topics[topicUid].topic)
        .filter(topic => topic.length < 18 && topic !== previousTopic);

      return sampleSize(randomTopics, 1)[0];
    }
  }
}

function useTopicExample() {
  const { topicPacks } = useGameState();
  const getTopicPacks = useAction(getTopicPacksAction);

  const [randomTopic, setRandomTopic] = useState(
    randomTopicFromTopicPacks(topicPacks, '')
  );

  useEffect(() => {
    getTopicPacks();
  }, [getTopicPacks]);

  useEffect(() => {
    if (topicPacks) {
      if (!randomTopic) {
        setRandomTopic(prev => randomTopicFromTopicPacks(topicPacks, prev));
      } else {
        const timeout = setTimeout(() => {
          setRandomTopic(prev => randomTopicFromTopicPacks(topicPacks, prev));
        }, 5000);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [topicPacks, randomTopic]);

  return randomTopic;
}

export default useTopicExample;
