import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { isDesktop } from '/src/utilities/device';

import { useAction } from '/src/state';
import { openModalAction } from '/src/actions';
import { joinGameAction, JoinGameError } from '/src/actions/pre_game';
import useRouter from '/src/utilities/router';

import Button from '/src/components/shared/button';
import Loading from '/src/components/shared/loading';
import Logo from '/src/components/shared/logo';
import TextInput from '/src/components/shared/text_input';
import IconCircleI from '/src/components/shared/icons/circle_i';

function Join() {
  const joinGame = useAction(joinGameAction);
  const openModal = useAction(openModalAction);
  const routes = useRouter();
  const { search } = useLocation();

  const [name, setName] = useState('');
  const [gameId, setGameId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<JoinGameError | null>(null);

  const disabled = loading || !name || gameId.length !== 2;
  const sharedGameId = new URLSearchParams(search).get('gameId');

  useEffect(() => {
    if (sharedGameId) {
      setGameId(sharedGameId);
    }
  }, [sharedGameId]);

  function handleJoinGame() {
    setLoading(true);

    joinGame(gameId, name, false, routes).catch((error: JoinGameError) => {
      if (error && error?.details?.field) {
        if (error.details.field === 'game_id') {
          setGameId('');
        }

        if (error.details.field === 'name') {
          setName('');
        }
      }

      setLoading(false);
      setError(error);
    });
  }

  function handleGameIdChanged(value: string) {
    setGameId(value.toUpperCase());
  }

  return (
    <div className="flex flex-col h-full bg-color--primary md:items-center">
      <div className="mt-4 md:mt-8 md:flex-grow">
        <Logo size={isDesktop() ? 'med' : 'tiny'} />
      </div>
      <div className="cntnr flex flex-col items-center flex-grow m-6 mt-4 md:mt-6 pt-6 px-4 pb-4 md:w-2/3 lg:w-2/5 md:flex-grow-0">
        <h1 className="modal-header color--dark-gray">
          {error && 'Oops!'}
          {!error && loading && 'Joining...'}
          {!error && !loading && 'Join an Existing Game'}
        </h1>
        {error && error.details?.msg && (
          <h2 className="font-bold text-lg mt-1">{error.details.msg}</h2>
        )}
        {!loading && (
          <>
            <form
              autoComplete="off"
              className="flex flex-col flex-grow mt-8 w-3/4 md:w-1/2 lg:w-3/5"
            >
              <div className="mb-8">
                <TextInput
                  label="What's your name?"
                  name="name"
                  onChange={value => setName(value)}
                  placeholder=""
                  value={name}
                />
              </div>
              <div className="mb-8">
                <TextInput
                  label="What's the Game ID?"
                  name="game-id"
                  onChange={handleGameIdChanged}
                  placeholder="A6"
                  value={gameId}
                />
              </div>
              {error && error.message && (
                <p className="text-center">{error.message}</p>
              )}
              <div className="flex flex-col justify-end flex-grow mt-4 w-full">
                <Button
                  disabled={disabled}
                  fullWidth
                  name="join"
                  onClick={handleJoinGame}
                  variant="primary"
                >
                  JOIN
                </Button>
              </div>
            </form>
            <span className="self-end">
              <Button
                onClick={() =>
                  openModal(
                    <>
                      Don't have a game code? Make sure one player in the group
                      chooses START A NEW GAME on the previous screen. They'll
                      get a code to share with everyone.
                    </>
                  )
                }
                name="coachmark"
                variant="icon"
              >
                <IconCircleI />
              </Button>
            </span>
          </>
        )}
        {loading && (
          <div className="mt-4">
            <Loading />
          </div>
        )}
      </div>
      <div className="md:flex-grow" />
    </div>
  );
}

export default Join;
