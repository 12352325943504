import React from 'react';

import useThemeColor from '/src/components/shared/use_theme_color';
import PROPS from './props';

function IconPencil({ white }: PROPS) {
  const themeColor = useThemeColor();

  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.702 18.7054L18.7055 18.7019C18.7987 18.6093 18.8727 18.4993 18.9233 18.378C18.9738 18.2568 18.9999 18.1268 19 17.9954C18.9999 17.8849 18.9817 17.7751 18.946 17.6705L17.0137 11.9743L6.08291 1.04356C5.41463 0.375339 4.50826 -4.21587e-05 3.5632 -1.55752e-07C2.61814 3.99399e-05 1.7118 0.3755 1.04357 1.04378C0.375342 1.71206 -4.20873e-05 2.61842 -6.71332e-07 3.56347C4.07446e-05 4.50852 0.375504 5.41485 1.04379 6.08307L11.9746 17.0138L17.6707 18.9459C17.8479 19.0068 18.0386 19.0165 18.221 18.9739C18.4034 18.9314 18.5701 18.8383 18.702 18.7054ZM1.95143 5.17536C1.5266 4.74728 1.28873 4.16829 1.28988 3.56519C1.29104 2.96209 1.53114 2.38402 1.95761 1.95757C2.38408 1.53112 2.96216 1.29103 3.56526 1.28989C4.16837 1.28875 4.74736 1.52664 5.17544 1.95147L6.61285 3.38887L3.38884 6.61284L1.95143 5.17536ZM12.6704 15.8942L4.29648 7.52047L7.52049 4.2965L15.8943 12.6703L17.5495 17.5494L12.6704 15.8942Z"
        fill={white ? '#fff' : themeColor}
      />
    </svg>
  );
}

export default IconPencil;
