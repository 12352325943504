import React, { useEffect, useRef, useState } from 'react';

import { isDesktop } from '/src/utilities/device';

import { useAction, useGameState } from '/src/state';
import { openModalAction } from '/src/actions';
import { addTopicAction } from '/src/actions/pre_game';
import { toActivePlayers } from '/src/utilities/players';
import useRouter from '/src/utilities/router';
import { toPlayerTopics } from '/src/utilities/topics';

import Button from '/src/components/shared/button';
import Logo from '/src/components/shared/logo';
import TextInput from '/src/components/shared/text_input';
import IconCircleI from '/src/components/shared/icons/circle_i';

import Topic from '/src/components/add_topics/topic';
import useTopicExample from '/src/components/add_topics/use_topic_example';

function AddTopics() {
  const [error, setError] = useState<string | null>(null);
  const [topic, setTopic] = useState('');
  const [errorWidth, setErrorWidth] = useState<number>(-1);
  const topicInputRef = useRef(document.createElement('input'));

  const { toPlayers } = useRouter();
  const addTopic = useAction(addTopicAction);
  const openModal = useAction(openModalAction);
  const { gameId, game, playerUid } = useGameState();
  const topicExample = useTopicExample();

  useEffect(() => {
    if (topicInputRef.current) {
      setErrorWidth(topicInputRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (topic) {
      setError(null);
    }
  }, [topic]);

  if (!game) return null;

  const { numRounds, players, topics } = game;

  const activePlayers = toActivePlayers(players);
  const playerTopics = toPlayerTopics(topics, playerUid || '');
  const remainingPlayerTopics = 4 * numRounds - (playerTopics || []).length;

  function handleAddTopic() {
    addTopic(topic).catch((playerName: string) => {
      setError(`Ope! ${playerName} already added that...try again`);
    });
    setTopic('');

    if (topicInputRef.current) {
      topicInputRef.current.focus();
    }
  }

  return (
    <div className="flex flex-col items-center px-6 pb-6 h-full bg-color--primary">
      <div className="flex-shrink-0 flex justify-center items-center mt-4 md:mt-8 px-6 w-full">
        <span className="color--white font-bold">{gameId}</span>
        <span className="flex-grow md:flex-grow-0 md:mx-8">
          <Logo size={isDesktop() ? 'med' : 'tiny'} />
        </span>
        <Button
          onClick={() =>
            openModal(
              <>
                Topics are people, places and things that you’ll be asked to
                rank throughout the game. Good topics are things that are
                trivial yet polarizing. Here are a few examples of potential
                topics:
                <ul>
                  <li>&bull; Las Vegas</li>
                  <li>&bull; Dave Matthews Band</li>
                  <li>&bull; The Bachelor</li>
                  <li>&bull; Going to the movies alone</li>
                </ul>
                Here are some things to avoid:
                <ul>
                  <li>
                    &bull; "Either/ors" like "cats or dogs". That’s an entirely
                    different game. Just enter "cats!" Better yet, enter "Cats!
                    The Musical"
                  </li>
                  <li>
                    &bull; Broad categories like "airlines" or "music". It’s fun
                    to get specific. Instead, try "Delta Airlines" or "Jazz"
                  </li>
                </ul>
              </>
            )
          }
          name="coachmark"
          variant="icon"
        >
          <IconCircleI white />
        </Button>
      </div>
      <div className="cntnr flex flex-col items-center flex-grow mt-4 md:mt-6 py-6 overflow-hidden w-full lg:w-1/2">
        <h1 className="modal-header color--dark-gray mb-6">
          {remainingPlayerTopics > 1 &&
            `Enter ${remainingPlayerTopics} More Topics`}
          {remainingPlayerTopics === 1 && 'Enter 1 More Topic'}
          {remainingPlayerTopics <= 0 && 'Enough Topics Added'}
        </h1>
        <form
          autoComplete="off"
          className="flex-shrink-0 flex justify-center px-4 w-full"
          onSubmit={handleAddTopic}
        >
          <div className="flex flex-col mr-2 md:w-3/5">
            <TextInput
              disabled={remainingPlayerTopics === 0}
              getRef={topicInputRef}
              label="Polarizing Thing"
              name="topic"
              onChange={setTopic}
              placeholder={topicExample ? `e.g. ${topicExample}` : undefined}
              value={topic}
            />
          </div>
          <Button
            disabled={!topic || topic.trim().length === 0}
            name="add"
            onClick={handleAddTopic}
            variant="primary"
          >
            Add
          </Button>
        </form>
        {error && (
          <span
            className="self-start color--primary text-sm font-bold ml-6 mt-1"
            style={{ width: errorWidth }}
          >
            {error}
          </span>
        )}
        <div className="flex-grow flex flex-col mt-8 pl-10 pr-5 overflow-y-auto w-full md:w-2/3 md:pl-0 md:pr-0">
          {[...playerTopics].reverse().map((topic, index, arr) => (
            <div key={topic.uid} className="mb-10">
              <Topic topic={topic} />
            </div>
          ))}
        </div>
        <hr className="w-full" />
        <div className="flex-shrink-0 flex flex-col items-center pt-4">
          <span className="font-bold mb-5">
            {numRounds} round{numRounds > 1 && 's'} |{' '}
            {`${activePlayers.length} players`}
          </span>
          {remainingPlayerTopics > 0 && (
            <span className="color--light-gray mt-2">Keep adding topics!</span>
          )}
          {remainingPlayerTopics === 0 && (
            <Button name="done" onClick={toPlayers}>
              Done Adding Topics
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddTopics;
