import React from 'react';

import PROPS from './props';
import useThemeColor from '/src/components/shared/use_theme_color';

function IconCircleI({ white }: PROPS) {
  const themeColor = useThemeColor();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37321 5.37321 0 12 0C18.6268 0 24 5.37321 24 12C24 18.6268 18.6268 24 12 24C5.37321 24 0 18.6268 0 12ZM2.03571 12C2.03571 17.5018 6.49821 21.9643 12 21.9643C17.5018 21.9643 21.9643 17.5018 21.9643 12C21.9643 6.49821 17.5018 2.03571 12 2.03571C6.49821 2.03571 2.03571 6.49821 2.03571 12ZM12.0001 5.67853C13.1251 5.67853 14.1858 6.06424 14.9894 6.76871C15.8251 7.49996 16.2858 8.48299 16.2831 9.53567C16.2831 11.0839 15.2626 12.4767 13.6822 13.0848C13.4396 13.1785 13.2309 13.3431 13.0833 13.5571C12.9356 13.7712 12.8559 14.0247 12.8545 14.2848V14.8928C12.8545 15.0107 12.7581 15.1071 12.6402 15.1071H11.3545C11.2367 15.1071 11.1402 15.0107 11.1402 14.8928V14.3169C11.1402 13.6982 11.3224 13.0875 11.6733 12.5785C12.0188 12.0803 12.501 11.7 13.0688 11.483C13.9822 11.1321 14.5715 10.3687 14.5715 9.53567C14.5715 8.35442 13.417 7.39281 12.0001 7.39281C10.5831 7.39281 9.42864 8.35442 9.42864 9.53567V9.73924C9.42864 9.8571 9.33221 9.95353 9.21436 9.95353H7.92864C7.81078 9.95353 7.71436 9.8571 7.71436 9.73924V9.53567C7.71436 8.48299 8.17507 7.49996 9.01078 6.76871C9.81435 6.06692 10.8751 5.67853 12.0001 5.67853ZM11.2425 18.6504C11.0415 18.4495 10.9286 18.177 10.9286 17.8928C10.9286 17.6087 11.0415 17.3361 11.2425 17.1352C11.4434 16.9343 11.7159 16.8214 12.0001 16.8214C12.2842 16.8214 12.5568 16.9343 12.7577 17.1352C12.9586 17.3361 13.0715 17.6087 13.0715 17.8928C13.0715 18.177 12.9586 18.4495 12.7577 18.6504C12.5568 18.8514 12.2842 18.9642 12.0001 18.9642C11.7159 18.9642 11.4434 18.8514 11.2425 18.6504Z"
        fill={white ? '#fff' : themeColor}
      />
    </svg>
  );
}

export default IconCircleI;
