import React, { Component } from 'react';

import Button from '/src/components/shared/button';
import Logo from '/src/components/shared/logo';
import { logErrorMessageService } from '/src/services';

type PROPS = {
  children: React.ReactNode;
};

type STATE = {
  hasError: boolean;
};

function getErrorMessage(error: any) {
  if (process.env.NODE_ENV !== 'production') console.log(error);

  const {
    location: { pathname }
  } = window;
  const path = pathname.substring(pathname.lastIndexOf('/'));

  return `${path} | ${error.message} | ${error.stack}`;
}

class ErrorBoundary extends Component<PROPS, STATE> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    if (process.env.NODE_ENV !== 'production') console.log(error);

    logErrorMessageService(getErrorMessage(error), process.env.NODE_ENV);
  }

  handleClick = () => {
    window.location.href = '/';
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (!hasError) return children;

    return (
      <div className="bg-color--primary h-full flex flex-col items-center p-4">
        <div className="mt-8">
          <Logo size="small" />
        </div>
        <h1 className="modal-header color--white my-6">oops...</h1>
        <div className="flex-grow flex flex-col justify-around items-center text-center color--white font-bold text-lg">
          <span>...we're embarassed, but something went wrong.</span>
          <span>Maybe try refreshing the page?</span>
          <span>
            Or{' '}
            <span className="text-base">
              <Button
                name="return-home"
                onClick={this.handleClick}
                variant="secondary"
              >
                return home
              </Button>
            </span>{' '}
            and rejoin the fun!
          </span>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;
