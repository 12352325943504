import React from 'react';

export type PLAYER = {
  uid?: string;
  active: boolean;
  isThisPlayer?: boolean;
  lockedIn: boolean;
  name: string;
  rankingTimes: number[];
  lateJoiner: boolean;
};
export type PLAYER_OBJ = { [key: string]: PLAYER };

export type TOPIC = {
  uid?: string;
  playerUid?: string;
  rank: number;
  status: string;
  topic: string;
};
export type TOPIC_OBJ = { [key: string]: TOPIC };

export type GUESSES_OBJ = {
  [key: string]: { [key: string]: number | string };
};

export type LIKE_OBJ = {
  [key: string]: { [key: string]: boolean };
};

export type WHATS_NEW_OBJ = {
  [key: string]: { date: string; desc: string };
};

export enum GameMode {
  Classic = 'classic',
  HotSeat = 'hotSeat'
}

export type GAME = {
  gameUid?: string;
  guesses?: GUESSES_OBJ;
  hotSeatUid?: string;
  likes?: LIKE_OBJ;
  mode: GameMode;
  numRounds: number;
  players: PLAYER_OBJ;
  rankingPlayerUid: string;
  state: string;
  startDate: string;
  started: boolean;
  topicPack: boolean;
  topics?: TOPIC_OBJ;
};

export type TOPIC_PACK = {
  uid: string;
  category: string;
  live: boolean;
  name: string;
  owner?: string;
  topics: { [key: string]: { topic: string } };
};

export type STATE = {
  game?: GAME;
  gameId?: string;
  gameUid?: string;
  localRanks?: { [key: string]: number };
  modalIsOpen?: boolean;
  modalContent?: React.ReactNode;
  name?: string;
  playerUid?: string;
  rankingStartTime?: number | null;
  theme?: string;
  topicPacks?: TOPIC_PACK[];
  topicPackOwner?: string;
  whatsNew?: WHATS_NEW_OBJ;
};
