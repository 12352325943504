import {
  GAME,
  GUESSES_OBJ,
  GameMode,
  LIKE_OBJ,
  PLAYER_OBJ,
  TOPIC_OBJ
} from '/src/state/types';
import { toTopicsArray } from '/src/utilities/topics';

export function toPlayersArray(players: PLAYER_OBJ) {
  return Object.keys(players || {}).map((uid: string) => ({
    uid,
    ...players[uid]
  }));
}

export function toActivePlayers(players: PLAYER_OBJ) {
  return toPlayersArray(players).filter(({ active }) => active);
}

export function toActiveOnTimePlayers(players: PLAYER_OBJ) {
  return toActivePlayers(players).filter(({ lateJoiner }) => !lateJoiner);
}

export function toPlayersWithTopicsCount(
  players: PLAYER_OBJ,
  topics: TOPIC_OBJ | undefined
) {
  const topicsArray = toTopicsArray(topics);

  return toActivePlayers(players).map(player => ({
    ...player,
    numTopics: topicsArray.filter(
      ({ playerUid }) => playerUid === (player ? player.uid : '-1')
    ).length
  }));
}

export function toAllPlayersWithLikes(
  likes: LIKE_OBJ,
  players: PLAYER_OBJ,
  topics: TOPIC_OBJ
) {
  const topicLikes = Object.values(likes).reduce<{ [key: string]: number }>(
    (accum, playerLikes) => ({
      ...accum,
      ...Object.keys(playerLikes)
        .filter(topicUid => playerLikes[topicUid])
        .reduce(
          (topicUids, topicUid) => ({
            ...topicUids,
            [topicUid]: (accum[topicUid] || 0) + 1
          }),
          {}
        )
    }),
    {}
  );

  const topicsWithLikes = Object.keys(topicLikes).map(topicUid => ({
    ...topics[topicUid],
    numLikes: topicLikes[topicUid]
  }));

  const playerLikes = topicsWithLikes.reduce<{ [key: string]: number }>(
    (accum, topicLike) => ({
      ...accum,
      [topicLike.playerUid || '']:
        (accum[topicLike.playerUid || ''] || 0) + topicLike.numLikes
    }),
    {}
  );

  return Object.keys(players)
    .map(playerUid => ({
      uid: playerUid,
      ...players[playerUid],
      numLikes: playerLikes[playerUid] || 0
    }))
    .sort(
      ({ numLikes: numLikesA }, { numLikes: numLikesB }) =>
        numLikesB - numLikesA
    );
}

export function toAllPlayersWithScores(game: GAME) {
  const { guesses, hotSeatUid, mode, players, topics } = game;

  const playerScores: { [key: string]: number } = Object.keys(
    guesses || {}
  ).reduce((playerScores, playerUid) => {
    const playerGuesses = (guesses || {})[playerUid];

    const playerScore = Object.keys(playerGuesses).reduce((score, topicUid) => {
      if (!topics) return 0;

      const playerGuess = playerGuesses[topicUid];
      const topic = topics[topicUid];
      const correctGuess = playerGuess === topic.rank ? 1 : 0;

      return score + correctGuess;
    }, 0);

    return { ...playerScores, [playerUid]: playerScore };
  }, {});

  return toPlayersArray(players)
    .filter(player => !(mode === GameMode.HotSeat && player.uid === hotSeatUid))
    .map(player => ({
      ...player,
      score: playerScores[player.uid] || 0
    }))
    .sort(({ score: scoreA }, { score: scoreB }) => scoreB - scoreA);
}

export function toUnlockedInPlayers(players: PLAYER_OBJ) {
  return toPlayersArray(players).filter(
    ({ lockedIn, active }) => !lockedIn && active
  );
}

export function toPlayer(players: PLAYER_OBJ, playerUid: string) {
  return { ...players[playerUid], uid: playerUid };
}

export function toActivePlayerTurns(
  players: PLAYER_OBJ,
  guesses: GUESSES_OBJ = {}
) {
  return toActiveOnTimePlayers(players).reduce<{ [key: string]: number }>(
    (playerTurns, player) => ({
      ...playerTurns,
      [player.uid]:
        Object.values(guesses[player.uid] || []).filter(val => val === 'active')
          .length / 4
    }),
    {}
  );
}
