import React from 'react';

import useThemeColor, {
  useLightestThemeColor
} from '/src/components/shared/use_theme_color';

function IconFrowneySmall() {
  const themeColor = useThemeColor();
  const lightestThemeColor = useLightestThemeColor();

  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.5C14.2076 0.5 16.1803 0.949529 17.9272 1.81629C19.6764 2.70865 21.048 3.92157 22.0333 5.477C23.0167 7.02949 23.5 8.76962 23.5 10.7368C23.5 12.7041 23.0167 14.4442 22.0333 15.9967C21.0465 17.5546 19.699 18.7664 17.9526 19.6574C16.2061 20.524 14.2334 20.9737 12 20.9737C9.76144 20.9737 7.79107 20.5464 6.0499 19.6586C4.30231 18.7676 2.95398 17.5553 1.9667 15.9967C0.9833 14.4442 0.5 12.7041 0.5 10.7368C0.5 8.76962 0.9833 7.02949 1.9667 5.477C2.95353 3.91909 4.30109 2.70721 6.04752 1.81628C7.79364 0.949905 9.79146 0.5 12 0.5Z"
        fill={lightestThemeColor}
        stroke={themeColor}
      />
      <path
        d="M6.94535 8.30526C7.00219 7.93263 7.37356 7.57895 7.89777 7.57895C8.42198 7.57895 8.79335 7.93263 8.85019 8.30526C8.85984 8.38961 8.88639 8.47115 8.92826 8.54501C8.97013 8.61886 9.02646 8.68352 9.09388 8.73511C9.1613 8.7867 9.23844 8.82417 9.32067 8.84527C9.4029 8.86638 9.48855 8.87069 9.57248 8.85794C9.65642 8.8452 9.73693 8.81567 9.80919 8.7711C9.88145 8.72654 9.94399 8.66787 9.99306 8.59859C10.0421 8.52931 10.0767 8.45085 10.0948 8.3679C10.1129 8.28495 10.114 8.1992 10.0982 8.11579C9.93777 7.06105 8.97903 6.31579 7.89777 6.31579C6.81651 6.31579 5.85777 7.06105 5.69735 8.11579C5.68152 8.1992 5.68268 8.28495 5.70074 8.3679C5.7188 8.45085 5.75341 8.52931 5.80248 8.59859C5.85155 8.66787 5.91409 8.72654 5.98635 8.7711C6.05862 8.81567 6.13912 8.8452 6.22306 8.85794C6.30699 8.87069 6.39264 8.86638 6.47487 8.84527C6.5571 8.82417 6.63424 8.7867 6.70166 8.73511C6.76908 8.68352 6.82541 8.61886 6.86728 8.54501C6.90916 8.47115 6.93571 8.38961 6.94535 8.30526Z"
        fill={themeColor}
      />
      <path
        d="M16.1083 7.57895C15.5841 7.57895 15.214 7.93263 15.1559 8.30526C15.1254 8.46588 15.0339 8.60842 14.9005 8.70291C14.7671 8.79741 14.6022 8.8365 14.4406 8.81196C14.279 8.78742 14.1331 8.70117 14.0338 8.57134C13.9345 8.4415 13.8893 8.27821 13.9079 8.11579C14.0683 7.06105 15.0271 6.31579 16.1083 6.31579C17.1896 6.31579 18.1483 7.06105 18.3087 8.11579C18.3273 8.27821 18.2822 8.4415 18.1828 8.57134C18.0835 8.70117 17.9377 8.78742 17.7761 8.81196C17.6144 8.8365 17.4496 8.79741 17.3162 8.70291C17.1828 8.60842 17.0912 8.46588 17.0607 8.30526C17.0026 7.93263 16.6325 7.57895 16.1083 7.57895Z"
        fill={themeColor}
      />
      <path
        d="M17.1163 16.4211C17.1956 16.421 17.274 16.4061 17.3465 16.3775C17.4189 16.3489 17.4838 16.3071 17.5371 16.2549C17.5903 16.2026 17.6306 16.1411 17.6554 16.0741C17.6803 16.0072 17.6891 15.9364 17.6813 15.8663C17.4108 13.3976 15.2303 11.3684 12.0004 11.3684C8.77061 11.3684 6.58897 13.3976 6.3184 15.8663C6.31062 15.9365 6.31947 16.0074 6.34439 16.0744C6.36931 16.1414 6.40974 16.203 6.46307 16.2553C6.51641 16.3075 6.58147 16.3493 6.65406 16.3778C6.72665 16.4064 6.80517 16.4211 6.88456 16.4211L17.1163 16.4211ZM12.0004 12.3789C14.3663 12.3789 15.9977 13.6957 16.4478 15.4105L7.55189 15.4105C8.00323 13.6957 9.63349 12.3789 12.0004 12.3789Z"
        fill={themeColor}
      />
    </svg>
  );
}

export default IconFrowneySmall;
