import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import 'typeface-roboto';
import '/src/style/index.scss';

import App from '/src/components/app';
import GameStateProvider from '/src/state/provider';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <GameStateProvider>
    <Router>
      <App />
    </Router>
  </GameStateProvider>
);
