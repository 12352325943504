import React from 'react';

import useThemeColor, {
  useLightestThemeColor
} from '/src/components/shared/use_theme_color';

function IconSmileySmall() {
  const themeColor = useThemeColor();
  const lightestThemeColor = useLightestThemeColor();

  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.5C14.2076 0.5 16.1803 0.949529 17.9272 1.81629C19.6764 2.70864 21.048 3.92157 22.0333 5.477C23.0167 7.02949 23.5 8.76962 23.5 10.7368C23.5 12.7041 23.0167 14.4442 22.0333 15.9967C21.0465 17.5546 19.699 18.7664 17.9526 19.6574C16.2061 20.524 14.2334 20.9737 12 20.9737C9.76144 20.9737 7.79107 20.5464 6.0499 19.6586C4.30231 18.7676 2.95398 17.5553 1.9667 15.9967C0.9833 14.4442 0.5 12.7041 0.5 10.7368C0.5 8.76962 0.9833 7.02949 1.9667 5.477C2.95353 3.91909 4.30109 2.70721 6.04752 1.81628C7.79364 0.949905 9.79146 0.5 12 0.5Z"
        fill={lightestThemeColor}
        stroke={themeColor}
      />
      <path
        d="M6.94535 8.30527C7.00219 7.93264 7.37356 7.57895 7.89777 7.57895C8.42198 7.57895 8.79335 7.93264 8.85019 8.30527C8.85984 8.38962 8.88639 8.47116 8.92826 8.54501C8.97013 8.61887 9.02646 8.68352 9.09388 8.73511C9.1613 8.7867 9.23844 8.82417 9.32067 8.84528C9.4029 8.86638 9.48855 8.87069 9.57248 8.85795C9.65642 8.84521 9.73693 8.81567 9.80919 8.77111C9.88145 8.72655 9.94399 8.66788 9.99306 8.5986C10.0421 8.52932 10.0767 8.45086 10.0948 8.3679C10.1129 8.28495 10.114 8.19921 10.0982 8.1158C9.93777 7.06106 8.97903 6.3158 7.89777 6.3158C6.81651 6.3158 5.85777 7.06106 5.69735 8.1158C5.68152 8.19921 5.68268 8.28495 5.70074 8.3679C5.7188 8.45086 5.75341 8.52932 5.80248 8.5986C5.85155 8.66788 5.91409 8.72655 5.98635 8.77111C6.05862 8.81567 6.13912 8.84521 6.22306 8.85795C6.30699 8.87069 6.39264 8.86638 6.47487 8.84528C6.5571 8.82417 6.63424 8.7867 6.70166 8.73511C6.76908 8.68352 6.82541 8.61887 6.86728 8.54501C6.90916 8.47116 6.93571 8.38962 6.94535 8.30527Z"
        fill={themeColor}
      />
      <path
        d="M16.1083 7.57895C15.5841 7.57895 15.214 7.93264 15.1559 8.30527C15.1254 8.46588 15.0339 8.60842 14.9005 8.70292C14.7671 8.79741 14.6022 8.8365 14.4406 8.81196C14.279 8.78742 14.1331 8.70117 14.0338 8.57134C13.9345 8.44151 13.8893 8.27822 13.9079 8.1158C14.0683 7.06106 15.0271 6.3158 16.1083 6.3158C17.1896 6.3158 18.1483 7.06106 18.3087 8.1158C18.3273 8.27822 18.2822 8.44151 18.1828 8.57134C18.0835 8.70117 17.9377 8.78742 17.7761 8.81196C17.6144 8.8365 17.4496 8.79741 17.3162 8.70292C17.1828 8.60842 17.0912 8.46588 17.0607 8.30527C17.0026 7.93264 16.6325 7.57895 16.1083 7.57895Z"
        fill={themeColor}
      />
      <path
        d="M6.88343 11.3684C6.80414 11.3685 6.72575 11.3833 6.65329 11.412C6.58084 11.4406 6.51591 11.4823 6.4627 11.5346C6.40949 11.5868 6.36916 11.6484 6.34431 11.7153C6.31946 11.7823 6.31064 11.8531 6.31841 11.9232C6.58898 14.3919 8.76949 16.4211 11.9993 16.4211C15.2291 16.4211 17.4108 14.3919 17.6814 11.9232C17.6891 11.853 17.6803 11.7821 17.6554 11.7151C17.6304 11.6481 17.59 11.5865 17.5367 11.5342C17.4833 11.482 17.4183 11.4402 17.3457 11.4116C17.2731 11.3831 17.1946 11.3684 17.1152 11.3684H6.88343ZM11.9993 15.4105C9.6335 15.4105 8.0021 14.0938 7.55191 12.3789H16.4479C15.9965 14.0938 14.3663 15.4105 11.9993 15.4105Z"
        fill={themeColor}
      />
    </svg>
  );
}

export default IconSmileySmall;
