import { useNavigate } from 'react-router-dom';

import { useGameState } from '/src/state';

export type ROUTES = {
  toRoot: () => void;
  toCreate: () => void;
  toJoin: () => void;
  toAddTopics: () => void;
  toPlayers: () => void;
  toGame: () => void;
  toEnd: () => void;
};

function useRouter() {
  const navigate = useNavigate();

  const { gameId } = useGameState();

  function toRoot() {
    navigate('/', { replace: true });
  }

  function toCreate() {
    navigate('/create');
  }

  function toJoin() {
    navigate('/join');
  }

  function toAddTopics() {
    navigate(`/${gameId}/topics`, { replace: true });
  }

  function toPlayers() {
    navigate(`/${gameId}/players`, { replace: true });
  }

  function toGame() {
    navigate(`/${gameId}/game`, { replace: true });
  }

  function toEnd() {
    navigate(`/${gameId}/end`, { replace: true });
  }

  return { toRoot, toCreate, toJoin, toAddTopics, toPlayers, toGame, toEnd };
}

export default useRouter;
