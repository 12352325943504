function spaceSeparate(...strings: string[]) {
  return strings.join(' ');
}

function compactObj(obj: { [key: string]: any }) {
  return Object.keys(obj).filter(key => !!obj[key]);
}

function classNames(...args: any[]) {
  return args.reduce((memo, arg) => {
    if (arg === undefined) return memo.trim();

    if (typeof arg === 'string') {
      return spaceSeparate(memo, arg).trim();
    }

    const keys = compactObj(arg);
    return spaceSeparate(memo, ...keys).trim();
  }, '');
}

export default classNames;
