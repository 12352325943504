import React from 'react';

import cx from '/src/utilities/cx';
import { useAction } from '/src/state';
import { openModalAction } from '/src/actions';

import Button from '/src/components/shared/button';
import IconCircleI from '/src/components/shared/icons/circle_i';
import { GameMode } from '/src/state/types';

interface RoundsProps {
  mode: GameMode | null;
  numRounds: number;
  setNumRounds: (numRounds: number) => void;
}

function ClassicCoachmark() {
  return (
    <>
      Each player ranks once per round. The more rounds you choose, the longer
      the game will last.
      <br />
      <br />
      For groups of 2-4, we suggest playing 3-4 rounds. For groups of 5 or more,
      we suggest playing 1-2 rounds.
    </>
  );
}

function HotSeatCoachmark() {
  return (
    <>
      The person in the hot seat ranks once per person per round. The more
      rounds you choose, the longer the game will last
      <br />
      <br />
      We suggest playing 1-2 rounds for most hot seat games.
    </>
  );
}

function Rounds({ mode, numRounds, setNumRounds }: RoundsProps) {
  const openModal = useAction(openModalAction);

  const roundClasses = (roundNum: number) =>
    cx('border-b-2 mb-7 px-4 pb-4 font-bold', {
      'border-transparent': roundNum !== numRounds,
      'b-color--primary text-black': roundNum === numRounds
    });

  return (
    <div className="flex-grow w-full flex flex-col items-center">
      <h1 className="modal-header color--dark-gray mb-8">
        How many rounds do you want to play?
      </h1>
      <div className="flex-grow flex flex-col items-center text-3xl color--light-gray">
        {[1, 2, 3, 4].map(num => (
          <button
            key={num}
            className={roundClasses(num)}
            onClick={() => setNumRounds(num)}
          >
            {num}
          </button>
        ))}
      </div>
      <span className="self-end">
        <Button
          onClick={() =>
            openModal(
              mode === GameMode.HotSeat ? (
                <HotSeatCoachmark />
              ) : (
                <ClassicCoachmark />
              )
            )
          }
          name="coachmark"
          variant="icon"
        >
          <IconCircleI />
        </Button>
      </span>
    </div>
  );
}

export default Rounds;
