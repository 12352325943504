import { useContext } from 'react';

import GameStateContext from '/src/state/context';
import { STATE } from '/src/state/types';
import { getLocalGameState } from '/src/utilities/local_storage';

function useGameState(): STATE {
  const { state } = useContext(GameStateContext);
  const { theme, ...rest } = getLocalGameState();

  return { theme, ...state, ...rest };
}

function useAction(action: Function) {
  const { actionWrapper } = useContext(GameStateContext);

  return actionWrapper(action);
}

export { useAction, useGameState };
