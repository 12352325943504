import React from 'react';

import Button from '/src/components/shared/button';
import IconInstagram from '/src/components/shared/icons/instagram';
import IconTwitter from '/src/components/shared/icons/twitter';
import IconFacebook from '/src/components/shared/icons/facebook';

type PROPS = {
  whiteBackground?: boolean;
};

function Social({ whiteBackground }: PROPS) {
  return (
    <div className="flex flex-col justify-center items-center mt-4">
      <div className="w-60">
        <Button
          href="mailto:feedback@topfour.io"
          fullWidth
          link
          name="send-feedback"
          variant={whiteBackground ? 'secondary' : 'secondary-invert'}
        >
          Send Us Feedback
        </Button>
      </div>
      <div className="flex items-center mt-6">
        <a
          className="mr-4"
          href="https://instagram.com/topfour.io"
          target="_blank"
          rel="noreferrer"
        >
          <IconInstagram white={!whiteBackground} />
        </a>
        <a
          className="mx-4"
          href="https://twitter.com/TopFourGame"
          target="_blank"
          rel="noreferrer"
        >
          <IconTwitter white={!whiteBackground} />
        </a>
        <a
          className="ml-4"
          href="https://www.facebook.com/TopFourGame/"
          target="_blank"
          rel="noreferrer"
        >
          <IconFacebook white={!whiteBackground} />
        </a>
      </div>
    </div>
  );
}

export default Social;
