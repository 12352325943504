import React from 'react';

import useRouter from '/src/utilities/router';
import { useAction } from '/src/state';
import { clearAllStateAction } from '/src/actions';

import Button from '/src/components/shared/button';
import Social from '/src/components/shared/social';

function NewGame() {
  const { toRoot } = useRouter();
  const clearAllState = useAction(clearAllStateAction);

  function handleNewGame() {
    clearAllState();
    toRoot();
  }

  return (
    <div className="flex-grow w-full flex flex-col justify-between items-center">
      <h1 className="modal-header color--dark-gray mb-6">Play again?</h1>
      <div className="flex flex-col items-center">
        <Button fullWidth name="start-new-game" onClick={handleNewGame}>
          Start New Game
        </Button>
        <Social whiteBackground />
      </div>
    </div>
  );
}

export default NewGame;
