import React from 'react';

import useThemeColor from '/src/components/shared/use_theme_color';
import './style.scss';

function Loading() {
  const themeColor = useThemeColor();

  return (
    <div className="loading">
      <svg
        width="92"
        height="86"
        viewBox="0 0 92 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M69.5823 5.48519C62.5949 1.86105 54.7342 0 46 0C37.2658 0 29.308 1.86105 22.3207 5.48519C15.3333 9.20729 9.89874 14.3007 5.91984 20.8633C1.94093 27.426 0 34.7722 0 43C0 51.2278 1.94093 58.574 5.91984 65.1367C9.89874 71.6993 15.3333 76.7927 22.3207 80.5148C29.308 84.2369 37.1688 86 46 86C54.8312 86 62.692 84.139 69.6793 80.5148C76.6667 76.7927 82.1013 71.6993 86.0802 65.1367C90.0591 58.574 92 51.2278 92 43C92 34.7722 90.0591 27.426 86.0802 20.8633C82.1013 14.3007 76.5696 9.20729 69.5823 5.48519ZM37.1688 64.9408L36.9747 64.7449L30.3755 58.0843L18.0506 45.6446L24.7468 38.8861L37.0717 51.3257L67.9325 20.1777L74.6287 26.9362L37.1688 64.9408Z"
          fill={themeColor}
        />
      </svg>
    </div>
  );
}

export default Loading;
