import React from 'react';
import { useEffect } from 'react';
import {
  getWhatsNewUids,
  saveWhatsNewUids
} from '/src/utilities/local_storage';

import { getWhatsNewAction, openModalAction } from '/src/actions';
import { useAction, useGameState } from '/src/state';

import WhatsNew from '/src/components/whats_new';

interface UseWhatsNewProps {
  delay?: number;
  skipCache?: boolean;
}

function useWhatsNew(args: UseWhatsNewProps = { delay: 0, skipCache: false }) {
  const openModal = useAction(openModalAction);
  const getWhatsNew = useAction(getWhatsNewAction);

  const { whatsNew } = useGameState();

  useEffect(() => {
    setTimeout(() => getWhatsNew(), args.delay);
  }, []);

  useEffect(() => {
    if (whatsNew) {
      const whatsNewUids = Object.keys(whatsNew);
      const seenWhatsNewUids = args.skipCache ? [] : getWhatsNewUids();
      saveWhatsNewUids(Object.keys(whatsNew));

      const newWhatsNewUids = whatsNewUids.filter(
        uid => seenWhatsNewUids.includes(uid) === false
      );

      if (newWhatsNewUids.length) {
        openModal(<WhatsNew whatsNew={whatsNew} />);
      }
    }
  }, [whatsNew]);

  return whatsNew;
}

export default useWhatsNew;
