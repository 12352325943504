import ReactGA from 'react-ga';

let initialized = false;
function maybeInitialize() {
  if (process.env.NODE_ENV !== 'production') return;

  if (!initialized) {
    ReactGA.initialize('UA-143830686-1', {
      titleCase: false
    });
    initialized = true;
  }
}

export function pageView(page: string) {
  maybeInitialize();

  if (process.env.NODE_ENV !== 'test') {
    ReactGA.pageview(page);
  }
}
