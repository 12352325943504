import seedrandom from 'seedrandom';

export enum SUPERLATIVE_TYPE {
  WINNER,
  LATE_JOINER_WINNER,
  WINNER_TIE,
  LOSER,
  LOSER_TIE,
  OPEN_BOOK,
  OPEN_BOOK_TIE,
  STRANGER,
  STRANGER_TIE,
  TORTOISE,
  TORTOISE_WINNER,
  TORTOISE_LOSER,
  HARE,
  HARE_WINNER,
  HARE_LOSER,
  HOT_TOPIC,
  PARTICIPATION,
  LATE_JOINER,
  LATE_JOINER_TIE
}

const SUPERLATIVE_COPY = {
  [SUPERLATIVE_TYPE.WINNER]: [
    {
      header: 'The Winner',
      subheader: 'Congrats!',
      footer: (percentCorrect: number) =>
        `${percentCorrect}% of your guesses were correct!`
    },
    {
      header: 'The Winner',
      subheader: 'Winner Winner Chicken Dinner',
      footer: (percentCorrect: number) =>
        `${percentCorrect}% of your guesses were correct!`
    },
    {
      header: 'The Winner',
      subheader: 'Woohoo!',
      footer: (percentCorrect: number) =>
        `${percentCorrect}% of your guesses were correct!`
    },
    {
      header: 'The Winner',
      subheader: 'You should put this on your resume',
      footer: (percentCorrect: number) =>
        `${percentCorrect}% of your guesses were correct!`
    }
  ],
  [SUPERLATIVE_TYPE.LATE_JOINER_WINNER]: [
    {
      header: 'The Winner',
      subheader: 'Congrats!',
      footer: `*joining late is technically cheating`
    }
  ],
  [SUPERLATIVE_TYPE.WINNER_TIE]: [
    {
      header: 'The Winners',
      subheader: "There's a tie!",
      footer: 'Maybe arm wrestle for it?'
    },
    {
      header: 'The Winners',
      subheader: "There's a tie!",
      footer: 'This is equally as exciting as someone actually winning'
    }
  ],
  [SUPERLATIVE_TYPE.LOSER]: [
    {
      header: 'The Loser',
      subheader: 'Womp, womp...',
      footer: (percentWrong: number) =>
        `${percentWrong}% of your guesses were wrong :(`
    },
    {
      header: 'The Loser',
      subheader: "It's only because you scored the least points",
      footer: (percentWrong: number) =>
        `${percentWrong}% of your guesses were wrong :(`
    },
    {
      header: 'The Loser',
      subheader: "Don't worry, it's only a game that you're bad at",
      footer: (percentWrong: number) =>
        `${percentWrong}% of your guesses were wrong :(`
    },
    {
      header: 'The Loser',
      subheader: 'At least you had fun, right?',
      footer: (percentWrong: number) =>
        `${percentWrong}% of your guesses were wrong :(`
    }
  ],
  [SUPERLATIVE_TYPE.LOSER_TIE]: [
    {
      header: 'The Losers',
      subheader: 'So much mediocrity',
      footer: 'At least you lost together...'
    },
    {
      header: 'The Losers',
      subheader: 'Misery loves company',
      footer: 'At least you lost together...'
    }
  ],
  [SUPERLATIVE_TYPE.OPEN_BOOK]: [
    {
      header: 'The Open Book',
      subheader: 'No need to judge you by your cover',
      footer: (numRounds: number) =>
        `The group scored the most points on your turn${
          numRounds > 1 ? 's' : ''
        }!`
    },
    {
      header: 'The Open Book',
      subheader:
        'Don\'t think of it as being "basic", think of it as being... unsurprising',
      footer: (numRounds: number) =>
        `The group scored the most points on your turn${
          numRounds > 1 ? 's' : ''
        }!`
    },
    {
      header: 'The Open Book',
      subheader: 'No surprises here',
      footer: (numRounds: number) =>
        `The group scored the most points on your turn${
          numRounds > 1 ? 's' : ''
        }!`
    },
    {
      header: 'The Open Book',
      subheader: 'Obviously',
      footer: (numRounds: number) =>
        `The group scored the most points on your turn${
          numRounds > 1 ? 's' : ''
        }!`
    }
  ],
  [SUPERLATIVE_TYPE.OPEN_BOOK_TIE]: [
    {
      header: 'The Open Books',
      subheader: 'A few of you need some more mystery in your life',
      footer: `The group scored the most points on your turns!`
    }
  ],
  [SUPERLATIVE_TYPE.STRANGER]: [
    {
      header: 'The Complete Stranger',
      subheader: 'Do you know anyone here?',
      footer: (lowestScore: number) =>
        lowestScore === 0
          ? 'The group scored ZERO points guessing your rankings!'
          : `The group only scored ${lowestScore} point${
              lowestScore !== 1 ? 's' : ''
            } guessing your rankings!`
    },
    {
      header: 'The Complete Stranger',
      subheader: 'At least you know yourself',
      footer: (lowestScore: number) =>
        lowestScore === 0
          ? 'The group scored ZERO points guessing your rankings!'
          : `The group only scored ${lowestScore} point${
              lowestScore !== 1 ? 's' : ''
            } guessing your rankings!`
    }
  ],
  [SUPERLATIVE_TYPE.STRANGER_TIE]: [
    {
      header: 'The Complete Strangers',
      subheader: 'Did you come here together?',
      footer: (lowestScore: number) =>
        lowestScore === 0
          ? 'The group scored ZERO points on your turns!'
          : `The group only scored ${lowestScore} point${
              lowestScore !== 1 ? 's' : ''
            } on your turns!`
    }
  ],
  [SUPERLATIVE_TYPE.TORTOISE]: [
    {
      header: 'The Tortoise',
      subheader: "You're the reason we need a time limit in this game",
      footer: (averageRankingTime: number) =>
        `On average, you took ${Math.round(
          averageRankingTime / 1000
        )} seconds to lock in 😱`
    }
  ],
  [SUPERLATIVE_TYPE.TORTOISE_WINNER]: [
    {
      header: 'The Tortoise',
      subheader: 'Slow and steady wins the race!',
      footer: (averageRankingTime: number) =>
        `On average, you took ${Math.round(
          averageRankingTime / 1000
        )} seconds to lock in 😱`
    }
  ],
  [SUPERLATIVE_TYPE.TORTOISE_LOSER]: [
    {
      header: 'The Tortoise',
      subheader: "Well you lost, but you're slow",
      footer: (averageRankingTime: number) =>
        `On average, you took ${Math.round(
          averageRankingTime / 1000
        )} seconds to lock in 😱`
    }
  ],
  [SUPERLATIVE_TYPE.HARE]: [
    {
      header: 'The Hare',
      subheader: "Let's just say, you're quick to judge...",
      footer: (averageRankingTime: number) =>
        `On average, you took ${Math.round(
          averageRankingTime / 1000
        )} seconds to lock in⚡`
    }
  ],
  [SUPERLATIVE_TYPE.HARE_WINNER]: [
    {
      header: 'The Hare',
      subheader: 'Looks like your instincts served you well!',
      footer: (averageRankingTime: number) =>
        `On average, you took ${Math.round(
          averageRankingTime / 1000
        )} seconds to lock in⚡`
    }
  ],
  [SUPERLATIVE_TYPE.HARE_LOSER]: [
    {
      header: 'The Hare',
      subheader: 'Maybe you should slow your roll next time...',
      footer: (averageRankingTime: number) =>
        `On average, you took ${Math.round(
          averageRankingTime / 1000
        )} seconds to lock in⚡`
    },
    {
      header: 'The Hare',
      subheader: 'Well you lost, but at least you did it quickly',
      footer: (averageRankingTime: number) =>
        `On average, you took ${Math.round(
          averageRankingTime / 1000
        )} seconds to lock in⚡`
    }
  ],
  [SUPERLATIVE_TYPE.HOT_TOPIC]: [
    {
      header: 'The Hot Topic',
      subheader: 'They put should put this in a museum',
      footer: 'Your topic got the most likes!'
    },
    {
      header: 'The Hot Topic',
      subheader: 'Trivial, yet polarizing',
      footer: 'Your topic had the most likes!'
    },
    {
      header: 'The Hot Topic',
      subheader: 'Taste really is subjective, huh?',
      footer: 'Your topic had the most likes!'
    }
  ],
  [SUPERLATIVE_TYPE.PARTICIPATION]: [
    {
      header: 'The Participation Award',
      subheader: 'And you were here too!',
      footer: 'Thanks for playing!'
    },
    {
      header: 'The Participation Award',
      subheader: 'Maybe try losing next time?',
      footer: 'Thanks for playing!'
    }
  ],
  [SUPERLATIVE_TYPE.LATE_JOINER]: [
    {
      header: 'The Late Joiner',
      subheader: 'Did they forget to invite you?',
      footer: 'Better late than never.'
    },
    {
      header: 'The Late Joiner',
      subheader: 'Did they forget to invite you?',
      footer: 'Try being early next time.'
    }
  ],
  [SUPERLATIVE_TYPE.LATE_JOINER_TIE]: [
    {
      header: 'The Late Joiners',
      subheader: 'Were you hanging out together?',
      footer: 'Try being early next time.'
    }
  ]
};

export type COPY_GETTER = (
  superlativeType: SUPERLATIVE_TYPE,
  recipient: string,
  arg?: any
) => {
  header: string;
  subheader: string;
  recipient: string;
  footer: string;
};

export default function getCopyGetter(seed: string): COPY_GETTER {
  return function getCopy(
    superlativeType: SUPERLATIVE_TYPE,
    recipient: string,
    arg?: any
  ) {
    const copies = SUPERLATIVE_COPY[superlativeType];

    let copy;

    if (copies.length === 1 || process.env.NODE_ENV === 'test') {
      copy = copies[0];
    } else {
      const rand = seedrandom(seed);
      const index = Math.floor(rand() * copies.length);

      copy = copies[index];
    }

    const { header, subheader, footer } = copy;

    return {
      header,
      subheader,
      recipient,
      footer: typeof footer === 'function' ? footer(arg) : footer
    };
  };
}
