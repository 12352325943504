import React, { createContext } from 'react';
import { ACTION } from 'state/reducer';
import { STATE } from 'state/types';

type CONTEXT = {
  actionWrapper: (fn: Function) => Function;
  dispatch: React.Dispatch<ACTION>;
  state: STATE;
};

const GameStateContext = createContext<CONTEXT>({
  actionWrapper: () => () => {},
  dispatch: () => {},
  state: {}
});

export default GameStateContext;
