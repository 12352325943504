import React, { useState } from 'react';

import cx from '/src/utilities/cx';

import Button from '/src/components/shared/button';
import IconCheck from '/src/components/shared/icons/check';
import IconOutlineX from '/src/components/shared/icons/outline_x';

type PROPS = {
  confirmText?: string;
  confirmAction?: Function;
  disabled?: boolean;
  helperText?: string;
  skipConfirm?: boolean;
};

function ConfirmButton({
  confirmText,
  confirmAction,
  disabled,
  helperText,
  skipConfirm
}: PROPS) {
  const [confirming, setConfirming] = useState(false);

  const actionsClasses = cx('w-4/6 flex justify-around items-center mx-2', {
    invisible: !confirmAction
  });

  const helperTextClasses = cx('font-bold mb-2', {
    invisible: !helperText || confirming
  });

  function handleClick() {
    if (skipConfirm) {
      confirmAction && confirmAction();
    } else {
      setConfirming(true);
    }
  }

  function handleConfirm() {
    setConfirming(false);
    confirmAction && confirmAction();
  }

  return (
    <div className="flex flex-col items-center w-full">
      <span data-testid="confirm-helper-text" className={helperTextClasses}>
        {helperText}
      </span>
      <div className={actionsClasses}>
        {confirming && (
          <Button
            name="confirm-cancel"
            onClick={() => setConfirming(false)}
            variant="icon"
          >
            <IconOutlineX />
          </Button>
        )}

        {!confirming && (
          <Button
            disabled={disabled}
            fullWidth
            name="confirm-action"
            onClick={handleClick}
          >
            {confirmText || ''}
          </Button>
        )}
        {confirming && <span className="font-bold">You sure?</span>}

        {confirming && (
          <Button name="confirm-confirm" onClick={handleConfirm} variant="icon">
            <IconCheck />
          </Button>
        )}
      </div>
    </div>
  );
}

export default ConfirmButton;
