import { ACTION_PARAMS } from '/src/actions/types';
import { subscribeToGameUpdatesAction } from '/src/actions/subscribe';
import {
  addTopicService,
  createGameService,
  deleteTopicService,
  joinGameService,
  updateGameService
} from '/src/services';
import { GAME_STATE } from '/src/utilities/constants';
import log from '/src/utilities/logger';
import { ROUTES } from '/src/utilities/router';
import { randomizeThemeAction, setGameIdentifiersAction } from '/src/actions';
import { GameMode } from '/src/state/types';

interface JoinGameResponse {
  gameId: string;
  gameUid: string;
  name: string;
  playerUid: string;
  started: boolean;
  startDate: string;
  topicPack: boolean;
}

interface CreateGameResponse {
  gameId: string;
  gameUid: string;
  playerUid: string;
  startDate: string;
}

export interface JoinGameError {
  status:
    | 'invalid-argument'
    | 'not-found'
    | 'already-exists'
    | 'deadline-exceeded'
    | 'internal';
  message: string;
  details?: { field?: 'game_id' | 'name'; msg?: string };
}

export async function createGameAction(
  name: string,
  topicPackUids: string[],
  numRounds: number,
  mode: GameMode,
  { dispatch, state }: ACTION_PARAMS
) {
  const data = (await createGameService(
    topicPackUids,
    numRounds,
    name,
    document.referrer,
    mode
  ).catch(log)) as CreateGameResponse;

  if (!data || !data.gameId || !data.gameUid) {
    return Promise.reject('cannot start game');
  }

  const { gameId, gameUid, playerUid, startDate } = data;

  setGameIdentifiersAction(gameId, gameUid, playerUid, name, startDate, null, {
    dispatch,
    state
  });

  return subscribeToGameUpdatesAction(gameUid, playerUid, {
    dispatch,
    state
  });
}

export async function joinGameAction(
  joinGameId: string,
  joinName: string,
  rejoiner: boolean,
  { toAddTopics, toPlayers, toGame }: ROUTES,
  { dispatch, state }: ACTION_PARAMS
) {
  try {
    const response = await joinGameService(joinGameId, joinName, rejoiner);

    const { gameId, gameUid, name, playerUid, started, startDate, topicPack } =
      response as JoinGameResponse;

    setGameIdentifiersAction(
      gameId,
      gameUid,
      playerUid,
      name,
      startDate,
      null,
      {
        dispatch,
        state
      }
    );

    return subscribeToGameUpdatesAction(gameUid, playerUid, {
      dispatch,
      state
    }).then(() => {
      if (started) {
        toGame();
      } else {
        if (topicPack) {
          toPlayers();
        } else {
          toAddTopics();
        }
      }

      randomizeThemeAction({ dispatch, state });
    });
  } catch (err) {
    return Promise.reject(err as JoinGameError);
  }
}

export function startGameAction({ state }: ACTION_PARAMS) {
  const { game, gameUid } = state;

  if (!game || !gameUid) return;

  const toUpdate = {
    numRounds: game.numRounds,
    state: GAME_STATE.STARTED,
    started: true
  };

  if (game.mode === GameMode.HotSeat) {
    const numPlayers = Object.keys(game.players).length;
    toUpdate.numRounds = numPlayers * game.numRounds;
  }

  updateGameService(toUpdate, gameUid);
}

export function addTopicAction(
  newTopic: string,
  { state: { game, gameUid, playerUid } }: ACTION_PARAMS
) {
  return new Promise<void>((resolve, reject) => {
    const { players, topics } = game || {};

    const duplicateTopic = Object.values(topics || {}).find(
      ({ topic: topicName }) =>
        topicName.toUpperCase().trim() === newTopic.toUpperCase().trim()
    );

    if (duplicateTopic) {
      const playerUid = duplicateTopic.playerUid;
      const playerName =
        players && playerUid && players[playerUid]
          ? players[playerUid].name
          : 'Someone';

      reject(playerName);
    } else {
      addTopicService(newTopic, playerUid || '', gameUid || '');

      resolve();
    }
  });
}

export function deleteTopicAction(
  topicUid: string,
  { state: { gameUid } }: ACTION_PARAMS
) {
  deleteTopicService(topicUid, gameUid || '');
}

export function setHotSeatUidAction(
  hotSeatUid: string,
  { state: { gameUid } }: ACTION_PARAMS
) {
  if (!gameUid) return;

  updateGameService({ hotSeatUid }, gameUid);
}

export function setTopicPackOwnerAction(
  topicPackOwner: string,
  { dispatch }: ACTION_PARAMS
) {
  dispatch({ type: 'set_topic_pack_owner', topicPackOwner });
}
