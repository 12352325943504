import { GAME, GameMode, PLAYER, STATE } from '/src/state/types';

import { toTopicsArray } from '/src/utilities/topics';
import {
  toActiveOnTimePlayers,
  toActivePlayerTurns,
  toPlayer,
  toUnlockedInPlayers
} from '/src/utilities/players';
import { GAME_STATE } from './constants';

export function toTurns(game: GAME) {
  const { numRounds, mode, players, topics } = game;

  const currentTurn = Math.floor(
    toTopicsArray(topics).filter(
      ({ status }) => status === 'unavailable' || status === 'ranked'
    ).length / 4
  );

  if (mode === GameMode.HotSeat) {
    const remainingTurns = numRounds - currentTurn;
    return [currentTurn, remainingTurns];
  }

  const remainingTurns =
    toActiveOnTimePlayers(players).length * numRounds - currentTurn;

  return [currentTurn, remainingTurns];
}

export type TURN_STATE = {
  state: string;
  ranker: boolean;
  unlockedInPlayers: PLAYER[];
  currentRanker: PLAYER;
  nextRanker?: PLAYER;
};

export function toTurnState(state: STATE): TURN_STATE | null {
  const { game, playerUid } = state;

  if (!game || !playerUid) return null;

  const { guesses, hotSeatUid, mode, numRounds, players } = game;

  const remoteGameState = game.state;
  const player = toPlayer(players, playerUid);
  const rankingPlayerUid = game.rankingPlayerUid;
  const currentRanker = toPlayer(players, rankingPlayerUid);
  const unlockedInPlayers = toUnlockedInPlayers(players);
  const activePlayers = toActiveOnTimePlayers(players);
  const playerTurns = toActivePlayerTurns(players, guesses);

  const ranker = playerUid === rankingPlayerUid;

  if (
    [GAME_STATE.BETWEEN_ROUNDS, GAME_STATE.STARTED].includes(remoteGameState)
  ) {
    let nextGameState;
    let nextRanker;

    if (mode === GameMode.HotSeat) {
      const hotSeatRanker = activePlayers.find(({ uid }) => uid === hotSeatUid);
      nextRanker = hotSeatRanker || activePlayers[0];

      nextGameState =
        nextRanker && playerTurns[nextRanker.uid] === numRounds
          ? GAME_STATE.END_GAME
          : GAME_STATE.BETWEEN_ROUNDS;
    } else {
      const previousRankerPosition = activePlayers.findIndex(
        ({ uid }) => uid === rankingPlayerUid
      );
      const previousRanker = activePlayers[previousRankerPosition];
      nextRanker =
        activePlayers[(previousRankerPosition + 1) % activePlayers.length];

      nextGameState =
        previousRanker &&
        previousRankerPosition === activePlayers.length - 1 &&
        playerTurns[previousRanker.uid] === numRounds
          ? GAME_STATE.END_GAME
          : GAME_STATE.BETWEEN_ROUNDS;
    }

    return {
      state: nextGameState,
      ranker,
      unlockedInPlayers,
      currentRanker,
      nextRanker: {
        ...nextRanker,
        isThisPlayer: nextRanker && playerUid === nextRanker.uid
      }
    };
  }

  if (remoteGameState === GAME_STATE.RANKING && !player.lockedIn) {
    return {
      state: GAME_STATE.RANKING,
      ranker,
      unlockedInPlayers,
      currentRanker
    };
  }

  return {
    state: GAME_STATE.LOCKED_IN,
    ranker,
    unlockedInPlayers,
    currentRanker
  };
}
