import React, { useEffect, useRef, useState } from 'react';

import { setRankingStartTimeAction } from '/src/actions/in_game';
import { toTurnState } from '/src/utilities/game';

import { GAME_STATE } from '/src/utilities/constants';
import { useAction, useGameState } from '/src/state';

import Info from '/src/components/game/info';
import GameTopics from '/src/components/game/game_topics';
import './style.scss';

const Game = () => {
  const [showInfo, setShowInfo] = useState(false);
  const [topicsTop, setTopicsTop] = useState(-1);

  const setRankingStartTime = useAction(setRankingStartTimeAction);
  const state = useGameState();
  const turnState = toTurnState(state);

  const previousGameState = useRef(turnState && turnState.state);

  useEffect(() => {
    if (turnState) {
      if (
        previousGameState.current === GAME_STATE.BETWEEN_ROUNDS &&
        turnState.state === GAME_STATE.RANKING
      ) {
        setRankingStartTime();
      }
    }

    previousGameState.current = turnState && turnState.state;
  }, [setRankingStartTime, turnState]);

  if (!turnState) return null;

  return (
    <div className="game h-full">
      <Info
        setTopicsTop={setTopicsTop}
        showInfo={showInfo}
        toggleShowInfo={() => setShowInfo(show => !show)}
        topicsTop={topicsTop}
      />
      <GameTopics
        gameState={state}
        turnState={turnState}
        hideInfo={() => setShowInfo(false)}
        showInfo={showInfo}
        topicsTop={topicsTop}
      />
    </div>
  );
};

export default Game;
