import React from 'react';

import cx from '/src/utilities/cx';

type PROPS = {
  correctTopic?: string;
  isCorrect: boolean;
  isRanker: boolean;
  showPercent: boolean;
  tappable?: boolean;
  topic: string;
};

function Topic({
  correctTopic,
  isCorrect,
  isRanker,
  showPercent,
  tappable,
  topic
}: PROPS) {
  const topicClasses = cx('text-left', {
    'line-through': !isRanker && showPercent && !isCorrect
  });

  const Component = tappable ? 'button' : 'span';

  return (
    <div className="flex flex-col justify-center items-start">
      <Component data-cy="topic-val" className={topicClasses}>
        {topic}
      </Component>
      {showPercent && !isCorrect && <span>{correctTopic}</span>}
    </div>
  );
}

export default Topic;
