import { GAME, GameMode, PLAYER } from '/src/state/types';

import getCopyGetter, {
  COPY_GETTER,
  SUPERLATIVE_TYPE
} from '/src/utilities/superlatives/copy';
import {
  toActivePlayers,
  toAllPlayersWithScores
} from '/src/utilities/players';

type TIMED_PLAYER = PLAYER & {
  slowestRankingTime: number;
  fastestRankingTime: number;
  averageRankingTime: number;
};

type TOPIC_GUESSES = {
  [key: string]: {
    correctRank: number;
    guesses: (number | string)[];
    ranker: string;
    numberCorrect: number;
    numberIncorrect: number;
  };
};

type RANKERS_STATS = {
  [key: string]: {
    numberCorrect: number;
    numberLied: number;
    numberPerfect: number;
  };
};

export type SUPERLATIVE = {
  header: string;
  subheader: string;
  recipient: string;
  footer: string;
};

export type HOT_TOPIC_SUPERLATIVE = SUPERLATIVE & { topic: string };

export function isHotTopicSuperlative(
  x: SUPERLATIVE | HOT_TOPIC_SUPERLATIVE
): x is HOT_TOPIC_SUPERLATIVE {
  return (x as HOT_TOPIC_SUPERLATIVE).topic !== undefined;
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}

function playerName(
  player: { name: string; lateJoiner: boolean },
  denoteLate = false
) {
  return `${player.name}${denoteLate && player.lateJoiner ? '*' : ''}`;
}

function joinPlayers(
  players: { name: string; lateJoiner: boolean }[],
  denoteLate = false
) {
  return players.map(player => playerName(player, denoteLate)).join(', ');
}

/**
 * Generates a list of superlatives, with semi-random text for each.
 *
 * The superlatives are listed below in order that they will appear to the user,
 * with any conditions that control if they are generated or not.
 *
 * The Winner
 * The Loser
 * The Open Book
 *  - greater than two players
 *  - classic game only
 * The Stranger
 *  - greater than two players
 *  - classic game only
 * The Tortoise
 * The Hare
 * The Hot Topic
 * The Participation Award
 * The Late Joiners
 */
function generateSuperlatives(gameData: GAME, gameId?: string): SUPERLATIVE[] {
  if (!gameData || !gameId) return [];

  const playerCount = toActivePlayers(gameData.players).length;
  const getCopy = getCopyGetter(gameId);

  const recipientUids: string[] = [];
  const superlatives: (SUPERLATIVE | null)[] = [];

  const [winnerUids, winnerSuperlative] = theWinner(gameData, getCopy);
  const [loserUids, loserSuperlative] = theLoser(gameData, getCopy);

  superlatives.push(winnerSuperlative, loserSuperlative);
  recipientUids.push(...winnerUids, ...loserUids);

  if (gameData.mode !== GameMode.HotSeat && playerCount > 2) {
    const topicGuesses = getTopicGuesses(gameData);
    const rankers = getRankers(topicGuesses);

    const [openBookUids, openBookSuperlative] = theOpenBook(
      gameData,
      rankers,
      getCopy
    );
    const [strangerUids, strangerSuperlative] = theStranger(
      gameData,
      rankers,
      getCopy
    );

    superlatives.push(openBookSuperlative, strangerSuperlative);
    recipientUids.push(...openBookUids, ...strangerUids);
  }

  const timedPlayers = getTimedPlayers(gameData);
  if (timedPlayers && timedPlayers.length > 0) {
    const [tortoiseUid, tortoiseSuperlative] = theTortoise(
      timedPlayers,
      winnerUids,
      loserUids,
      getCopy
    );
    const [hareUid, hareSuperlative] = theHare(
      timedPlayers,
      winnerUids,
      loserUids,
      getCopy
    );

    superlatives.push(tortoiseSuperlative, hareSuperlative);
    recipientUids.push(tortoiseUid, hareUid);
  }

  const [hotTopicUid, hotTopicSuperlative] = theHotTopic(gameData, getCopy);

  superlatives.push(hotTopicSuperlative);
  recipientUids.push(hotTopicUid);

  superlatives.push(theParticipationAward(gameData, recipientUids, getCopy));
  superlatives.push(theLateJoinersAward(gameData, getCopy));

  return superlatives.filter(notEmpty);
}

export function getTimedPlayers({ players }: GAME): TIMED_PLAYER[] {
  const timedPlayers = toActivePlayers(players)
    .filter(({ rankingTimes }) => !!rankingTimes)
    .map(player => ({
      ...player,
      slowestRankingTime: Math.max(...player.rankingTimes),
      fastestRankingTime: Math.min(...player.rankingTimes),
      averageRankingTime:
        player.rankingTimes.reduce((sum, rankingTime) => sum + rankingTime) /
        player.rankingTimes.length
    }));

  return timedPlayers;
}

export function getTopicGuesses({ guesses = {}, topics }: GAME) {
  return Object.keys(guesses).reduce<TOPIC_GUESSES>((accum, playerUid) => {
    Object.keys(guesses[playerUid]).forEach(topicUid => {
      if (!accum[topicUid]) {
        accum[topicUid] = {
          correctRank: topics ? topics[topicUid].rank : -1,
          guesses: [],
          ranker: '',
          numberCorrect: 0,
          numberIncorrect: 0
        };
      }

      if (guesses[playerUid][topicUid] === 'active') {
        accum[topicUid].ranker = playerUid;
      } else {
        const guess = guesses[playerUid][topicUid];

        accum[topicUid].guesses.push(guess);

        if (guess === accum[topicUid].correctRank) {
          accum[topicUid].numberCorrect += 1;
        } else {
          accum[topicUid].numberIncorrect += 1;
        }
      }
    });

    return accum;
  }, {});
}

export function getRankers(topicGuesses: TOPIC_GUESSES) {
  return Object.keys(topicGuesses).reduce<RANKERS_STATS>((accum, topicUid) => {
    const topicGuess = topicGuesses[topicUid];
    const rankerUid = topicGuess.ranker;

    if (!accum[rankerUid]) {
      accum[rankerUid] = {
        numberCorrect: 0,
        numberLied: 0,
        numberPerfect: 0
      };
    }

    accum[rankerUid].numberCorrect += topicGuess.numberCorrect;

    if (
      topicGuess.numberCorrect === 0 &&
      new Set(topicGuess.guesses).size === 1
    ) {
      accum[rankerUid].numberLied += 1;
    }

    if (topicGuess.numberIncorrect === 0) {
      accum[rankerUid].numberPerfect += 1;
    }

    return accum;
  }, {});
}

export function theWinner(
  game: GAME,
  getCopy: COPY_GETTER
): [string[], SUPERLATIVE] {
  const { guesses = {} } = game;

  const playerScores = toAllPlayersWithScores(game).filter(
    ({ active }) => active
  );

  const winningScore = playerScores[0].score;
  const winningPlayers = playerScores.filter(
    ({ score }) => score === winningScore
  );

  if (winningPlayers.length === 1) {
    const winningPlayer = winningPlayers[0];
    const totalGuesses = Object.values(guesses[winningPlayer.uid] || {}).filter(
      guess => guess !== 'active'
    ).length;
    const percentCorrect = Math.round(
      (winningPlayer.score / totalGuesses) * 100
    );

    if (winningPlayer.lateJoiner) {
      return [
        [winningPlayer.uid],
        getCopy(
          SUPERLATIVE_TYPE.LATE_JOINER_WINNER,
          playerName(winningPlayer, true)
        )
      ];
    }

    return [
      [winningPlayer.uid],
      getCopy(
        SUPERLATIVE_TYPE.WINNER,
        playerName(winningPlayer, true),
        percentCorrect
      )
    ];
  }

  if (winningPlayers.some(({ lateJoiner }) => lateJoiner)) {
    return [
      winningPlayers.map(({ uid }) => uid),
      getCopy(
        SUPERLATIVE_TYPE.LATE_JOINER_WINNER,
        joinPlayers(winningPlayers, true)
      )
    ];
  }

  return [
    winningPlayers.map(({ uid }) => uid),
    getCopy(SUPERLATIVE_TYPE.WINNER_TIE, joinPlayers(winningPlayers, true))
  ];
}

export function theLoser(
  game: GAME,
  getCopy: COPY_GETTER
): [string[], SUPERLATIVE | null] {
  const { guesses = {} } = game;

  const playerScores = toAllPlayersWithScores(game).filter(
    ({ active }) => active
  );

  const losingScore = playerScores[playerScores.length - 1].score;
  const losingPlayers = playerScores.filter(
    ({ score }) => score === losingScore
  );

  if (losingPlayers.length === playerScores.length) return [[], null];

  if (losingPlayers.length === 1) {
    const losingPlayer = losingPlayers[0];
    const totalGuesses = Object.values(guesses[losingPlayer.uid] || {}).filter(
      guess => guess !== 'active'
    ).length;
    const percentWrong =
      100 - Math.round((losingPlayer.score / totalGuesses) * 100);

    return [
      [losingPlayer.uid],
      getCopy(SUPERLATIVE_TYPE.LOSER, playerName(losingPlayer), percentWrong)
    ];
  }

  return [
    losingPlayers.map(({ uid }) => uid),
    getCopy(SUPERLATIVE_TYPE.LOSER_TIE, joinPlayers(losingPlayers))
  ];
}

export function theOpenBook(
  { numRounds, players }: GAME,
  rankers: RANKERS_STATS,
  getCopy: COPY_GETTER
): [string[], SUPERLATIVE | null] {
  const sortedRankers = Object.keys(rankers)
    .map(rankerUid => ({
      uid: rankerUid,
      ...rankers[rankerUid],
      ...players[rankerUid]
    }))
    .sort(
      ({ numberCorrect: numberCorrectA }, { numberCorrect: numberCorrectB }) =>
        numberCorrectB - numberCorrectA
    );

  if (sortedRankers.length === 0) return [[], null];

  const highestScore = sortedRankers[0].numberCorrect;
  const highestScorePlayers = sortedRankers.filter(
    ({ numberCorrect }) => numberCorrect === highestScore
  );

  if (highestScorePlayers.length === sortedRankers.length) return [[], null];

  if (highestScorePlayers.length === 1) {
    const mostPerfectPlayer = highestScorePlayers[0];

    return [
      [mostPerfectPlayer.uid],
      getCopy(
        SUPERLATIVE_TYPE.OPEN_BOOK,
        playerName(mostPerfectPlayer),
        numRounds
      )
    ];
  }

  return [
    highestScorePlayers.map(({ uid }) => uid),
    getCopy(SUPERLATIVE_TYPE.OPEN_BOOK_TIE, joinPlayers(highestScorePlayers))
  ];
}

export function theStranger(
  { players }: GAME,
  rankers: RANKERS_STATS,
  getCopy: COPY_GETTER
): [string[], SUPERLATIVE | null] {
  const sortedRankers = Object.keys(rankers)
    .map(rankerUid => ({
      uid: rankerUid,
      ...rankers[rankerUid],
      ...players[rankerUid]
    }))
    .sort(
      ({ numberCorrect: numberCorrectA }, { numberCorrect: numberCorrectB }) =>
        numberCorrectA - numberCorrectB
    );

  if (sortedRankers.length === 0) return [[], null];

  const lowestScore = sortedRankers[0].numberCorrect;
  const lowestScorePlayers = sortedRankers.filter(
    ({ numberCorrect }) => numberCorrect === lowestScore
  );

  if (lowestScorePlayers.length === sortedRankers.length) return [[], null];

  if (lowestScorePlayers.length === 1) {
    const lowestScorePlayer = lowestScorePlayers[0];

    return [
      [lowestScorePlayer.uid],
      getCopy(
        SUPERLATIVE_TYPE.STRANGER,
        playerName(lowestScorePlayer),
        lowestScore
      )
    ];
  }

  return [
    lowestScorePlayers.map(({ uid }) => uid),
    getCopy(
      SUPERLATIVE_TYPE.STRANGER_TIE,
      joinPlayers(lowestScorePlayers),
      lowestScore
    )
  ];
}

export function theTortoise(
  timedPlayers: TIMED_PLAYER[],
  winnerUids: string[],
  loserUids: string[],
  getCopy: COPY_GETTER
): [string, SUPERLATIVE] {
  const slowestAveragePlayers = [...timedPlayers].sort(
    ({ averageRankingTime: a }, { averageRankingTime: b }) => b - a
  );

  const slowestAveragePlayer = slowestAveragePlayers[0];
  const { averageRankingTime, uid = '' } = slowestAveragePlayer;

  if (winnerUids.includes(uid)) {
    return [
      uid,
      getCopy(
        SUPERLATIVE_TYPE.TORTOISE_WINNER,
        playerName(slowestAveragePlayer),
        averageRankingTime
      )
    ];
  }

  if (loserUids.includes(uid)) {
    return [
      uid,
      getCopy(
        SUPERLATIVE_TYPE.TORTOISE_LOSER,
        playerName(slowestAveragePlayer),
        averageRankingTime
      )
    ];
  }

  return [
    uid,
    getCopy(
      SUPERLATIVE_TYPE.TORTOISE,
      playerName(slowestAveragePlayer),
      averageRankingTime
    )
  ];
}

export function theHare(
  timedPlayers: TIMED_PLAYER[],
  winnerUids: string[],
  loserUids: string[],
  getCopy: COPY_GETTER
): [string, SUPERLATIVE] {
  const fastestAveragePlayers = [...timedPlayers].sort(
    ({ averageRankingTime: a }, { averageRankingTime: b }) => a - b
  );

  const fastestAveragePlayer = fastestAveragePlayers[0];
  const { averageRankingTime, uid = '' } = fastestAveragePlayer;

  if (winnerUids.includes(uid)) {
    return [
      uid,
      getCopy(
        SUPERLATIVE_TYPE.HARE_WINNER,
        playerName(fastestAveragePlayer),
        averageRankingTime
      )
    ];
  }

  if (loserUids.includes(uid)) {
    return [
      uid,
      getCopy(
        SUPERLATIVE_TYPE.HARE_LOSER,
        playerName(fastestAveragePlayer),
        averageRankingTime
      )
    ];
  }

  return [
    uid,
    getCopy(
      SUPERLATIVE_TYPE.HARE,
      playerName(fastestAveragePlayer),
      averageRankingTime
    )
  ];
}

export function theHotTopic(
  { likes, players, topicPack, topics }: GAME,
  getCopy: COPY_GETTER
): [string, HOT_TOPIC_SUPERLATIVE | null] {
  if (topicPack || !topics || !likes) {
    return ['', null];
  }

  const topicLikes = Object.values(likes).reduce<{ [key: string]: number }>(
    (accum, playerLikes) => ({
      ...accum,
      ...Object.keys(playerLikes)
        .filter(topicUid => playerLikes[topicUid])
        .reduce(
          (topicUids, topicUid) => ({
            ...topicUids,
            [topicUid]: (accum[topicUid] || 0) + 1
          }),
          {}
        )
    }),
    {}
  );

  const topicsWithLikes = Object.keys(topicLikes)
    .map(topicUid => ({
      ...topics[topicUid],
      numLikes: topicLikes[topicUid]
    }))
    .sort(
      ({ numLikes: numLikesA }, { numLikes: numLikesB }) =>
        numLikesB - numLikesA
    );

  if (topicsWithLikes.length === 0) return ['', null];

  const mostLiked = topicsWithLikes[0];
  const player = players[mostLiked.playerUid || ''];

  return [
    mostLiked.playerUid || '',
    {
      topic: mostLiked.topic,
      ...getCopy(SUPERLATIVE_TYPE.HOT_TOPIC, player.name)
    }
  ];
}

export function theParticipationAward(
  { players }: GAME,
  recipientUids: string[],
  getCopy: COPY_GETTER
): SUPERLATIVE | null {
  const participants = toActivePlayers(players).filter(
    ({ uid, lateJoiner }) => !lateJoiner && !recipientUids.includes(uid)
  );

  if (participants.length === 0) return null;

  return getCopy(SUPERLATIVE_TYPE.PARTICIPATION, joinPlayers(participants));
}

export function theLateJoinersAward(
  { players }: GAME,
  getCopy: COPY_GETTER
): SUPERLATIVE | null {
  const lateJoiners = toActivePlayers(players).filter(
    ({ lateJoiner }) => lateJoiner
  );

  if (lateJoiners.length === 0) return null;

  if (lateJoiners.length === 1)
    return getCopy(SUPERLATIVE_TYPE.LATE_JOINER, lateJoiners[0].name);

  return getCopy(
    SUPERLATIVE_TYPE.LATE_JOINER_TIE,
    joinPlayers(lateJoiners, false)
  );
}

export default generateSuperlatives;
