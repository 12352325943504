import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd';

import cx from '/src/utilities/cx';
import { STATE } from '/src/state/types';
import { GAME_STATE } from '/src/utilities/constants';
import { TURN_STATE } from '/src/utilities/game';

import { toActiveTopics, toGuessesByTopic } from '/src/utilities/topics';
import { useAction } from '/src/state';
import { updateLocalRanksAction } from '/src/actions/in_game';

import RankableTopic from '/src/components/game/topics/rankable_topic';

type PROPS = {
  gameState: STATE;
  turnState: TURN_STATE;
};

function DraggableTopics({ gameState, turnState }: PROPS) {
  const updateLocalRanks = useAction(updateLocalRanksAction);

  const { game, localRanks, playerUid } = gameState;

  if (!game || !playerUid) return null;

  function handleDragEnd({ source, destination }: DropResult) {
    if (!destination) return;

    const sourceIndex = source.index;
    const destIndex = destination.index;

    updateLocalRanks(activeTopics, sourceIndex, destIndex);
  }

  const activeTopics = toActiveTopics(game.topics);
  const guessesByTopic = toGuessesByTopic(game.guesses || {});
  const canLike = !game.topicPack;
  const playerLikes = game.likes?.[playerUid];

  const sortedByLocalRank = activeTopics.sort(({ uid: uidA }, { uid: uidB }) =>
    localRanks ? localRanks[uidA] - localRanks[uidB] : 1
  );

  return (
    <div className="rankable-topics flex-grow w-full overflow-y-auto mt-6 md:w-1/2">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {localRanks &&
                sortedByLocalRank.map((topic, index) => (
                  <Draggable
                    key={topic.uid}
                    draggableId={topic.uid}
                    index={index}
                    isDragDisabled={turnState.state !== GAME_STATE.RANKING}
                  >
                    {(provided, snapshot) => {
                      const containerClasses = cx(
                        'border-2 b-color--primary rounded-xl',
                        {
                          'shadow-lg': snapshot.isDragging
                        }
                      );

                      const topicClasses = cx('bg-color--white', {
                        'bg-color--primary-lightest': snapshot.isDragging
                      });

                      return (
                        <div
                          className="mb-5 outline-none items-center"
                          ref={provided.innerRef}
                          data-cy={`topic-${index}`}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <RankableTopic
                            activeTopics={activeTopics}
                            canLike={canLike}
                            containerClasses={containerClasses}
                            localRanks={localRanks}
                            playerLikes={playerLikes || {}}
                            guessesByTopic={guessesByTopic}
                            topic={topic}
                            topicClasses={topicClasses}
                            turnState={turnState}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default DraggableTopics;
