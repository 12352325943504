import React from 'react';

import { ACTION_PARAMS } from '/src/actions/types';
import { getTopicPacksService, getWhatsNewService } from '/src/services';
import {
  clearLocalGameState,
  saveLocalGameState,
  saveLocalTheme
} from '/src/utilities/local_storage';

export const themes = [
  'orange',
  'berry',
  'fuschia',
  'purple',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'mint',
  'green',
  'lime',
  'golden'
];

export function cycleThemeAction({
  dispatch,
  state: { theme: currentTheme }
}: ACTION_PARAMS) {
  const index = themes.indexOf(currentTheme || 'orange');
  const nextTheme = themes[(index + 1) % themes.length];

  saveLocalTheme(nextTheme);
  dispatch({ type: 'set_theme', theme: nextTheme });
}

export function randomizeThemeAction({ dispatch }: ACTION_PARAMS) {
  const randomTheme = themes[Math.floor(Math.random() * themes.length)];

  saveLocalTheme(randomTheme);
  dispatch({ type: 'set_theme', theme: randomTheme });
}

export async function getTopicPacksAction({ state, dispatch }: ACTION_PARAMS) {
  if (state.topicPacks && state.topicPacks.length > 0) return;

  const topicPacks = await getTopicPacksService();

  if (topicPacks) {
    const packs = Object.keys(topicPacks).map(uid => ({
      uid,
      ...topicPacks[uid]
    }));

    dispatch({ type: 'topic_packs', topicPacks: packs });
  }
}

export async function getWhatsNewAction({ state, dispatch }: ACTION_PARAMS) {
  if (state.whatsNew) return;

  const whatsNew = await getWhatsNewService();

  if (whatsNew) {
    dispatch({ type: 'whats_new', whatsNew });
  }
}

export function openModalAction(
  modalContent: React.ReactNode,
  { dispatch }: ACTION_PARAMS
) {
  dispatch({
    type: 'open_modal',
    modalContent
  });
}

export function closeModalAction({ dispatch }: ACTION_PARAMS) {
  dispatch({ type: 'close_modal' });
}

export function setGameIdentifiersAction(
  gameId: string,
  gameUid: string,
  playerUid: string,
  name: string,
  startDate: string,
  theme: string | null,
  { dispatch }: ACTION_PARAMS
) {
  if (theme) saveLocalTheme(theme);
  saveLocalGameState(gameId, gameUid, playerUid, name, startDate);
  dispatch({
    type: 'set_game_identifiers',
    gameId,
    gameUid,
    playerUid,
    name,
    theme
  });
}

export function clearAllStateAction({ dispatch }: ACTION_PARAMS) {
  clearLocalGameState();
  dispatch({ type: 'clear_state' });
}
