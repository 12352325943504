import React from 'react';

import Button from '/src/components/shared/button';
import IconDelete from '/src/components/shared/icons/delete';

import { useAction } from '/src/state';
import { TOPIC } from '/src/state/types';
import { deleteTopicAction } from '/src/actions/pre_game';

type PROPS = {
  topic: TOPIC;
};

const Topic = ({ topic: topicObj }: PROPS) => {
  const deleteTopic = useAction(deleteTopicAction);

  const { uid, topic } = topicObj;

  return (
    <div className="flex justify-between items--center">
      <span>{topic}</span>
      <Button
        name="delete-topic"
        onClick={() => deleteTopic(uid)}
        variant="icon"
      >
        <IconDelete />
      </Button>
    </div>
  );
};

export { Topic };
export default Topic;
