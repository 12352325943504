import React, { useEffect, useRef } from 'react';

import { useAction, useGameState } from '/src/state';
import { setGameIdentifiersAction } from '/src/actions';
import { subscribeToGameUpdatesAction } from '/src/actions/subscribe';
import { getLocalGameState } from '/src/utilities/local_storage';
import useRouter from '/src/utilities/router';

import Loading from '/src/components/shared/loading';

type PROPS = {
  children: React.ReactElement;
};

function GameRoot({ children }: PROPS) {
  const { toRoot } = useRouter();
  const { game } = useGameState();

  const setGameIdentifiers = useAction(setGameIdentifiersAction);
  const subscribe = useAction(subscribeToGameUpdatesAction);

  const { gameId, gameUid, playerUid, playerName, gameStartDate, theme } =
    getLocalGameState();

  const subscribed = useRef(false);

  useEffect(() => {
    if (subscribe && !game && gameUid && !subscribed.current) {
      subscribed.current = true;

      setGameIdentifiers(
        gameId,
        gameUid,
        playerUid,
        playerName,
        gameStartDate,
        theme
      );
      subscribe(gameUid, playerUid);
    }

    if (!game && !gameUid) {
      toRoot();
    }
  }, [
    game,
    gameId,
    gameStartDate,
    gameUid,
    playerUid,
    playerName,
    setGameIdentifiers,
    subscribe,
    theme,
    toRoot
  ]);

  if (game) return children;

  return (
    <div
      className="h-full w-full flex flex-col items-center justify-center"
      data-testid="root-loading"
    >
      <span className="modal-header mb-4">Loading</span>
      <Loading />
    </div>
  );
}

export default GameRoot;
