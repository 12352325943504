import React from 'react';

import useThemeColor from '/src/components/shared/use_theme_color';

function IconDelete() {
  const themeColor = useThemeColor();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.4165 2.75C9.4165 2.61193 9.52843 2.5 9.6665 2.5H14.3332C14.4712 2.5 14.5832 2.61193 14.5832 2.75V3.65352H9.4165V2.75ZM7.9165 3.65352V2.75C7.9165 1.7835 8.70001 1 9.6665 1H14.3332C15.2997 1 16.0832 1.7835 16.0832 2.75V3.65352H22C22.5523 3.65352 23 4.10123 23 4.65352C23 5.2058 22.5523 5.65352 22 5.65352H2C1.44772 5.65352 1 5.2058 1 4.65352C1 4.10123 1.44772 3.65352 2 3.65352H7.9165ZM5.21716 7.45709C5.16196 6.90757 4.67174 6.50684 4.12222 6.56205C3.5727 6.61725 3.17197 7.10747 3.22718 7.65699L4.68551 22.1746C4.73684 22.6856 5.16692 23.0747 5.6805 23.0747H18.3194C18.833 23.0747 19.2631 22.6856 19.3144 22.1746L20.7727 7.65699C20.8279 7.10747 20.4272 6.61725 19.8777 6.56205C19.3282 6.50684 18.8379 6.90757 18.7827 7.45709L17.4148 21.0747H6.58508L5.21716 7.45709ZM9.17443 7.7764C9.5878 7.75003 9.94428 8.06377 9.97065 8.47714L10.5262 17.1877C10.5526 17.6011 10.2388 17.9576 9.82546 17.9839C9.41209 18.0103 9.05561 17.6966 9.02924 17.2832L8.47369 8.57262C8.44732 8.15924 8.76106 7.80276 9.17443 7.7764ZM14.8255 7.7764C14.4121 7.75003 14.0557 8.06377 14.0293 8.47714L13.4737 17.1877C13.4474 17.6011 13.7611 17.9576 14.1745 17.9839C14.5879 18.0103 14.9443 17.6966 14.9707 17.2832L15.5263 8.57262C15.5526 8.15924 15.2389 7.80276 14.8255 7.7764Z"
        fill={themeColor}
      />
    </svg>
  );
}

export default IconDelete;
