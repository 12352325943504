import React from 'react';

import { GAME_STATE } from '/src/utilities/constants';
import { TURN_STATE } from '/src/utilities/game';

import IconChevronUp from '/src/components/shared/icons/chevron_up';
import Button from '/src/components/shared/button';

type PROPS = {
  turnState: TURN_STATE;
  hideInfo: () => void;
  showInfo: boolean;
};

function headerState(turnState: TURN_STATE) {
  const { state, ranker, nextRanker, currentRanker } = turnState;

  switch (state) {
    case GAME_STATE.END_GAME:
      return ['Game Over!', 'Who won?'];
    case GAME_STATE.BETWEEN_ROUNDS:
      if (nextRanker?.isThisPlayer) {
        return [`You're Up`, 'Time to start ranking!'];
      }

      return ['Waiting', '...for the next turn to start'];
    case GAME_STATE.RANKING:
      if (ranker) {
        return ['Your Turn', 'Rank these topics, best to worst'];
      }

      return [
        'Guess',
        `...how ${currentRanker.name} would rank these topics, best to worst`
      ];
    case GAME_STATE.LOCKED_IN:
      if (ranker) {
        return ['Your Turn', 'Reveal your ranking'];
      }

      return [`${currentRanker.name}'s Reveal`, `How'd ya do?`];
    default:
      throw new Error('unknown header state');
  }
}

const Header = ({ hideInfo, showInfo, turnState }: PROPS) => {
  const [header, subheader] = headerState(turnState);

  return (
    <div className="game-header text-center px-3">
      {showInfo && (
        <Button name="hide-info" onClick={hideInfo} variant="icon">
          <IconChevronUp />
        </Button>
      )}
      {!showInfo && (
        <>
          <h1 className="modal-header color--dark-gray mb-1">{header}</h1>
          <div className="font-bold height-4">{subheader}</div>
        </>
      )}
    </div>
  );
};

export default Header;
