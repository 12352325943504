import React from 'react';

import { GAME, STATE, TOPIC_PACK } from '/src/state/types';
import { WHATS_NEW_OBJ } from './types';

export type ACTION =
  | { type: 'set_theme'; theme: string }
  | { type: 'topic_packs'; topicPacks: TOPIC_PACK[] }
  | { type: 'game_update'; game: GAME }
  | { type: 'set_ranking_start_time'; rankingStartTime: number | null }
  | { type: 'update_local_ranks'; ranks: { [key: string]: number } }
  | { type: 'open_modal'; modalContent: React.ReactNode }
  | { type: 'close_modal' }
  | {
      type: 'set_game_identifiers';
      gameId: string;
      gameUid: string;
      playerUid: string;
      name: string;
      theme: string | null;
    }
  | { type: 'set_topic_pack_owner'; topicPackOwner: string }
  | { type: 'clear_state' }
  | { type: 'set_name'; name: string }
  | { type: 'whats_new'; whatsNew: WHATS_NEW_OBJ };

function gameStateReducer(state: STATE, action: ACTION): STATE {
  switch (action.type) {
    case 'game_update':
      return { ...state, game: action.game };
    case 'set_theme':
      return { ...state, theme: action.theme };
    case 'topic_packs':
      return { ...state, topicPacks: action.topicPacks };
    case 'set_ranking_start_time':
      return { ...state, rankingStartTime: action.rankingStartTime };
    case 'update_local_ranks':
      return { ...state, localRanks: action.ranks };
    case 'open_modal':
      return { ...state, modalIsOpen: true, modalContent: action.modalContent };
    case 'close_modal':
      return { ...state, modalIsOpen: false, modalContent: null };
    case 'set_game_identifiers':
      return {
        ...state,
        gameId: action.gameId,
        gameUid: action.gameUid,
        playerUid: action.playerUid,
        name: action.name,
        theme: action.theme || state.theme
      };
    case 'set_topic_pack_owner':
      return { ...state, topicPackOwner: action.topicPackOwner };
    case 'set_name':
      return { ...state, name: action.name };
    case 'clear_state':
      return {};
    case 'whats_new':
      return { ...state, whatsNew: action.whatsNew };
    default:
      throw new Error('unknown action type');
  }
}

export default gameStateReducer;
