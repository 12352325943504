import {
  endGameService,
  lockInService,
  setPlayerActiveService,
  setPlayerNameService,
  startRoundService,
  updateGameService,
  updatePlayerLikesService,
  updatePlayerRanksService
} from '/src/services';

import { ACTION_PARAMS } from '/src/actions/types';
import { TOPIC } from '/src/state/types';
import { GAME_STATE } from '/src/utilities/constants';
import { savePlayerName } from '/src/utilities/local_storage';
import { toActiveOnTimePlayers, toActivePlayers } from '/src/utilities/players';

export function deactivatePlayerAction(
  playerUid: string,
  { state: { gameUid, game } }: ACTION_PARAMS
) {
  if (!game || !gameUid) return;

  const { players, rankingPlayerUid } = game;

  if (playerUid === rankingPlayerUid) {
    const activePlayers = toActiveOnTimePlayers(players);
    const previousRankerPosition = activePlayers.findIndex(
      ({ uid }) => uid === rankingPlayerUid
    );
    const nextRankerUid =
      activePlayers[(previousRankerPosition + 1) % activePlayers.length].uid;

    const game = {
      rankingPlayerUid: nextRankerUid
    };

    updateGameService(game, gameUid);
  }

  setPlayerActiveService(playerUid, false, gameUid);
}

export function setRankingStartTimeAction({ dispatch }: ACTION_PARAMS) {
  dispatch({
    type: 'set_ranking_start_time',
    rankingStartTime: new Date().getTime()
  });
}

export function startRoundAction({
  state: { gameUid, playerUid }
}: ACTION_PARAMS) {
  if (!gameUid || !playerUid) return;

  startRoundService(gameUid, playerUid);
}

export function lockInAction(
  {
    dispatch,
    state: { gameUid, localRanks, playerUid, rankingStartTime, game }
  }: ACTION_PARAMS,
  rankingEndTime = new Date().getTime()
) {
  if (!game || !playerUid || !localRanks) return;

  const { players, rankingPlayerUid } = game;

  const active = playerUid === rankingPlayerUid;
  const guesses = Object.keys(localRanks).reduce(
    (topicGuesses, topicUid) => ({
      ...topicGuesses,
      [topicUid]: active ? 'active' : localRanks[topicUid]
    }),
    {}
  );

  const player = players[playerUid];

  if (rankingStartTime) {
    const rankingTime = rankingEndTime - rankingStartTime;

    lockInService(
      gameUid || '',
      guesses,
      {
        ...player,
        lockedIn: true,
        rankingTimes: [...(player.rankingTimes || []), rankingTime]
      },
      playerUid
    );
  } else {
    lockInService(
      gameUid || '',
      guesses,
      { ...player, lockedIn: true },
      playerUid
    );
  }

  dispatch({
    type: 'set_ranking_start_time',
    rankingStartTime: null
  });
}

export function endGameAction({ state: { gameUid } }: ACTION_PARAMS) {
  endGameService(gameUid || '');
}

export function updateLocalRanksAction(
  activeTopics: TOPIC[],
  sourceIndex: number,
  destinationIndex: number,
  { state: { gameUid, playerUid } }: ACTION_PARAMS
) {
  if (!gameUid || !playerUid) return;

  const reorderedTopics = [...activeTopics];
  const [removed] = reorderedTopics.splice(sourceIndex, 1);
  reorderedTopics.splice(destinationIndex, 0, removed);

  const newRanks = reorderedTopics.reduce(
    (localRanks, topic, index) => ({
      ...localRanks,
      [topic.uid || '']: index
    }),
    {}
  );

  updatePlayerRanksService(gameUid, playerUid, newRanks);
}

export function revealTopicAction(
  topicUid: string,
  { state: { gameUid, localRanks = {}, game } }: ACTION_PARAMS
) {
  if (!game) return;

  const { topics, state: currentState } = game;

  if (!topics) return;

  const fullyRanked =
    Object.keys(topics)
      .map(uid => ({ uid, ...topics[uid] }))
      .filter(({ status }) => status === 'ranked').length === 3;

  const newGame = {
    [`topics/${topicUid}`]: {
      ...topics[topicUid],
      rank: localRanks[topicUid],
      status: 'ranked'
    },
    state: fullyRanked ? GAME_STATE.BETWEEN_ROUNDS : currentState
  };

  updateGameService(newGame, gameUid || '');
}

export function updateNameAction(
  newName: string,
  { dispatch, state: { game, gameUid, name, playerUid } }: ACTION_PARAMS
) {
  if (newName === name || !playerUid || !gameUid || !game) return;

  const taken = toActivePlayers(game.players)
    .filter(({ uid }) => uid !== playerUid)
    .some(({ name }) => name === newName);

  if (taken) return;

  setPlayerNameService(playerUid, newName, gameUid);
  savePlayerName(newName);
  dispatch({ type: 'set_name', name: newName });
}

export function togglePlayerLikeAction(
  topicUid: string,
  { state: { game, gameUid, playerUid } }: ACTION_PARAMS
) {
  if (!game || !gameUid || !playerUid) return;

  const { likes = {} } = game;
  const playerLikes = likes[playerUid] || {};
  const toggled = { [topicUid]: !playerLikes[topicUid] };

  updatePlayerLikesService(gameUid, playerUid, toggled);
}
