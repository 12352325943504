import React, { useState } from 'react';

import { isDesktop } from '/src/utilities/device';
import cx from '/src/utilities/cx';

import { useAction, useGameState } from '/src/state';
import { GAME, PLAYER, GameMode } from '/src/state/types';
import { setHotSeatUidAction, startGameAction } from '/src/actions/pre_game';
import { toPlayersWithTopicsCount } from '/src/utilities/players';

import ConfirmButton from '/src/components/shared/confirm_button';
import Logo from '/src/components/shared/logo';
import Radio from '/src/components/shared/radio_input';

function confirmHelperText(hotSeatMissing: boolean, numPlayers: number) {
  if (hotSeatMissing) {
    return 'Pick who is in the hot seat';
  }

  if (numPlayers === 1) return 'Waiting for more players';

  return 'Everyone here?';
}

function topicPackFooter(
  hotSeatMissing: boolean,
  isLeadPlayer: boolean,
  leadPlayer: PLAYER,
  players: PLAYER[],
  startGame: Function
) {
  if (isLeadPlayer) {
    return (
      <ConfirmButton
        confirmText="START GAME"
        confirmAction={() => startGame()}
        disabled={hotSeatMissing || players.length === 1}
        helperText={confirmHelperText(hotSeatMissing, players.length)}
      />
    );
  } else {
    return (
      <>
        <span className="font-bold mb-4">Everyone here?</span>
        <span className="color--light-gray">
          Tell {leadPlayer.name} to start the game!
        </span>
      </>
    );
  }
}

function footer(
  hotSeatUid: string | undefined,
  isLeadPlayer: boolean,
  leadPlayer: PLAYER,
  mode: GameMode,
  players: PLAYER[],
  remainingPlayers: PLAYER[],
  startGame: Function,
  topicPack: boolean
) {
  const hotSeatMissing = mode === GameMode.HotSeat && !hotSeatUid;

  if (topicPack) {
    return topicPackFooter(
      hotSeatMissing,
      isLeadPlayer,
      leadPlayer,
      players,
      startGame
    );
  } else {
    if (remainingPlayers.length > 1) {
      return (
        <span className="color--light-gray">
          Waiting on {remainingPlayers.length} player
          {remainingPlayers.length > 1 ? 's' : ''} to enter topics
        </span>
      );
    }

    if (remainingPlayers.length === 1) {
      return (
        <span className="color--light-gray">
          Waiting on {remainingPlayers[0].name} to enter topics.
        </span>
      );
    }

    if (isLeadPlayer) {
      return (
        <ConfirmButton
          confirmText="START GAME"
          confirmAction={() => startGame()}
          disabled={hotSeatMissing || players.length === 1}
          helperText={confirmHelperText(hotSeatMissing, players.length)}
        />
      );
    }

    return (
      <>
        <span className="font-bold mb-4">Everyone here?</span>
        <span className="color--light-gray">
          Tell {leadPlayer.name} to start the game!
        </span>
      </>
    );
  }
}

function renderPlayers(
  game: GAME,
  playerUid: string,
  setHotSeatUid: Function,
  startGame: Function
) {
  const { hotSeatUid, mode, numRounds, players, topicPack, topics } = game;

  const mappedPlayers = toPlayersWithTopicsCount(players, topics);

  if (!mappedPlayers.length) return <div className="flex-grow" />;

  const leadPlayer = mappedPlayers[0];
  const isLeadPlayer = leadPlayer && leadPlayer.uid === playerUid;
  const requiredTopics = numRounds * 4;

  const remainingPlayers = mappedPlayers.filter(
    ({ numTopics }) => numTopics < requiredTopics
  );

  return (
    <div className="cntnr flex flex-col items-center flex-grow mt-4 md:mt-6 py-6 px-4 pb-4 overflow-hidden w-full lg:w-1/2">
      <h1 className="modal-header color--dark-gray mb-4">
        {mappedPlayers.length} Player{mappedPlayers.length > 1 ? 's' : ''}
      </h1>
      <div className="flex-grow flex flex-col px-6 overflow-y-auto w-full">
        {mappedPlayers.map((player, index) => (
          <div
            key={player.uid}
            data-testid={player.name}
            className="mb-4 flex items-center font-bold text-lg"
          >
            {mode === GameMode.Classic ? (
              <>
                <span className="color--primary inline-block mr-4">
                  {index + 1}.
                </span>
                <span>{player.name}</span>
              </>
            ) : (
              <Radio
                checked={player.uid === hotSeatUid}
                disabled={!isLeadPlayer}
                label={player.name}
                name={player.name}
                onChange={() => setHotSeatUid(player.uid)}
                value={player.uid}
              />
            )}
            <span>
              {player.uid === playerUid && (
                <span className="font-normal ml-1">(You)</span>
              )}
              {!topicPack &&
                player.uid !== playerUid &&
                player.numTopics < requiredTopics && (
                  <span className="font-normal ml-1">{`(${
                    requiredTopics - player.numTopics
                  } more topic${
                    requiredTopics - player.numTopics === 1 ? '' : 's'
                  })`}</span>
                )}
            </span>
          </div>
        ))}
      </div>
      {footer(
        hotSeatUid,
        isLeadPlayer,
        leadPlayer,
        mode,
        mappedPlayers,
        remainingPlayers,
        startGame,
        topicPack
      )}
    </div>
  );
}

function Players() {
  const { gameId, game, playerUid } = useGameState();

  const startGame = useAction(startGameAction);
  const setHotSeatUid = useAction(setHotSeatUidAction);

  if (!game || !playerUid) return null;

  return (
    <div className="flex flex-col items-center px-6 pb-6 h-full bg-color--primary">
      <div className="flex-shrink-0 flex justify-center items-center mt-4 md:mt-8 px-6 w-full">
        <span className="color--white font-bold">{gameId}</span>
        <span className="flex-grow md:flex-grow-0 md:mx-8">
          <Logo size={isDesktop() ? 'med' : 'tiny'} />
        </span>
        <span className="invisible">{gameId}</span>
      </div>
      {renderPlayers(game, playerUid, setHotSeatUid, startGame)}
    </div>
  );
}

export default Players;
