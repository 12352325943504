import React, { MutableRefObject } from 'react';

import cx from '/src/utilities/cx';
import './style.scss';

type PROPS = {
  disabled?: boolean;
  getRef?:
    | MutableRefObject<HTMLInputElement>
    | ((ref: HTMLInputElement) => void);
  label?: string;
  name: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  value: string;
};

function TextInput({
  disabled,
  getRef,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  value
}: PROPS) {
  const controlClasses = cx('text-input__control', {
    'with-placeholder': placeholder && !label,
    'without-placeholder': !placeholder
  });

  return (
    <div className="text-input__group">
      <input
        className={controlClasses}
        data-testid={name}
        disabled={disabled}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={({ target: { value } }) => onChange(value)}
        onFocus={onFocus}
        placeholder={placeholder || 'placeholder'}
        ref={getRef}
        type="text"
        value={value}
      />
      <label className="text-input__label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
}

export default TextInput;
