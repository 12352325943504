import React, { useEffect } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { useGameState } from '/src/state';
import { GAME_STATE } from '/src/utilities/constants';
import useRouter from '/src/utilities/router';
import { pageView } from '/src/utilities/ga';

import Modal from '/src/components/shared/modal';
import ErrorBoundary from '/src/components/shared/error_boundary';

import Create from '/src/components/create';
import Home from '/src/components/home';
import Join from '/src/components/join';
import GameRoot from '/src/components/game/game_root';
import AddTopics from '/src/components/add_topics';
import Players from '/src/components/players';
import Game from '/src/components/game';
import End from '/src/components/end';

function App() {
  const { theme, game } = useGameState();
  const { pathname } = useLocation();
  const { toGame, toEnd } = useRouter();
  const { state } = game || {};

  useEffect(() => {
    if (
      state === GAME_STATE.END_GAME &&
      !pathname.endsWith('/end') &&
      pathname !== '/'
    ) {
      toEnd();
    }

    if (
      state === GAME_STATE.STARTED &&
      (pathname.endsWith('/topics') || pathname.endsWith('/players')) &&
      pathname !== '/'
    ) {
      toGame();
    }
  }, [state, toGame, pathname, toEnd]);

  useEffect(() => {
    pageView(pathname);
  }, [pathname]);

  return (
    <div className={`h-full theme-${theme || 'orange'}`}>
      <ErrorBoundary>
        <Routes>
          <Route path="create" element={<Create />} />
          <Route path="join" element={<Join />} />
          <Route
            path=":gameId"
            element={
              <GameRoot>
                <Outlet />
              </GameRoot>
            }
          >
            <Route path={'topics'} element={<AddTopics />} />
            <Route path={'players'} element={<Players />} />
            <Route path={'game'} element={<Game />} />
            <Route path={'end'} element={<End />} />
          </Route>
          <Route index element={<Home />} />
        </Routes>
        <Modal />
      </ErrorBoundary>
    </div>
  );
}

export default App;
