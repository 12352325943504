import React from 'react';

import useThemeColor from '/src/components/shared/use_theme_color';

function IconCheck() {
  const themeColor = useThemeColor();

  return (
    <svg
      width="52"
      height="48"
      viewBox="0 0 52 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.3291 3.0615C35.3797 1.03872 30.9367 0 26 0C21.0633 0 16.5654 1.03872 12.616 3.0615C8.66667 5.13895 5.59494 7.98178 3.34599 11.6446C1.09705 15.3075 0 19.4077 0 24C0 28.5923 1.09705 32.6925 3.34599 36.3554C5.59494 40.0182 8.66667 42.861 12.616 44.9385C16.5654 47.0159 21.0084 48 26 48C30.9916 48 35.4346 46.9613 39.384 44.9385C43.3333 42.861 46.4051 40.0182 48.654 36.3554C50.903 32.6925 52 28.5923 52 24C52 19.4077 50.903 15.3075 48.654 11.6446C46.4051 7.98178 43.2785 5.13895 39.3291 3.0615ZM21.0084 36.246L20.8987 36.1367L17.1688 32.4191L10.2025 25.4761L13.9873 21.7039L20.9536 28.6469L38.3966 11.262L42.1814 15.0342L21.0084 36.246Z"
        fill={themeColor}
      />
    </svg>
  );
}

export default IconCheck;
