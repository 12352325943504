import React, { useEffect, useState } from 'react';

import { useAction } from '/src/state';
import {
  startRoundAction,
  lockInAction,
  endGameAction
} from '/src/actions/in_game';
import { TURN_STATE } from '/src/utilities/game';
import { GAME_STATE } from '/src/utilities/constants';

import ConfirmButton from '/src/components/shared/confirm_button';

type PROPS = {
  turnState: TURN_STATE;
};

function footerContentForState(
  endGame: Function,
  loading: boolean,
  lockIn: Function,
  setLoading: (loading: boolean) => void,
  startRound: Function,
  turnState: TURN_STATE
) {
  const { state, unlockedInPlayers, nextRanker } = turnState;

  function handleStartRound() {
    setLoading(true);

    startRound();
  }

  function handleEndGame() {
    setLoading(true);

    endGame();
  }

  switch (state) {
    case GAME_STATE.END_GAME:
      if (nextRanker?.isThisPlayer) {
        if (loading) {
          return (
            <div className="flex justify-center">
              <span className="font-bold text-lg">
                Calculating the winner...
              </span>
            </div>
          );
        }

        return (
          <ConfirmButton
            confirmText="REVEAL THE WINNER?"
            confirmAction={handleEndGame}
            skipConfirm
          />
        );
      }

      return (
        <ConfirmButton
          helperText={`Waiting on ${nextRanker?.name} to reveal the winner!`}
        />
      );
    case GAME_STATE.BETWEEN_ROUNDS:
      if (nextRanker?.isThisPlayer) {
        return (
          <ConfirmButton
            confirmText={loading ? 'Starting...' : 'START'}
            confirmAction={handleStartRound}
            disabled={loading}
            skipConfirm
          />
        );
      }

      return (
        <ConfirmButton
          helperText={`Tell ${nextRanker?.name} to start ranking!`}
        />
      );
    case GAME_STATE.RANKING:
      return <ConfirmButton confirmText="LOCK IN" confirmAction={lockIn} />;
    case GAME_STATE.LOCKED_IN:
      if (unlockedInPlayers.length === 0) {
        return <ConfirmButton helperText="Everyone's locked in!" />;
      }

      if (unlockedInPlayers.length === 1) {
        return (
          <ConfirmButton
            helperText={`Waiting on ${unlockedInPlayers[0].name} to lock in!`}
          />
        );
      }

      return (
        <ConfirmButton
          helperText={`Waiting on ${unlockedInPlayers.length} players to lock in!`}
        />
      );
  }
}

const Footer = ({ turnState }: PROPS) => {
  const [loading, setLoading] = useState(false);

  const startRound = useAction(startRoundAction);
  const lockIn = useAction(lockInAction);
  const endGame = useAction(endGameAction);

  useEffect(() => {
    if (
      loading &&
      ![GAME_STATE.BETWEEN_ROUNDS, GAME_STATE.END_GAME].includes(
        turnState.state
      )
    ) {
      setLoading(false);
    }
  }, [loading, turnState.state]);

  return (
    <div className="w-full md:w-1/2">
      {footerContentForState(
        endGame,
        loading,
        lockIn,
        setLoading,
        startRound,
        turnState
      )}
    </div>
  );
};

export default Footer;
