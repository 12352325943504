import React from 'react';

import './style.scss';

type PROPS = {
  checked: boolean;
  disabled?: boolean;
  label: string;
  name: string;
  onChange: () => void;
  value: string;
};

function Radio({ checked, disabled, label, name, onChange, value }: PROPS) {
  return (
    <div className="radio-input__group">
      <input
        checked={checked}
        disabled={disabled}
        className="radio-input__control"
        id={name}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <label className="radio-input__field" htmlFor={name} />
      <label className="radio-input__label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
}

export default Radio;
