import React, { useMemo, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { isDesktop } from '/src/utilities/device';
import cx from '/src/utilities/cx';
import generateSuperlatives from '/src/utilities/superlatives';

import { useGameState } from '/src/state';

import Button from '/src/components/shared/button';
import Logo from '/src/components/shared/logo';
import IconArrowRight from '/src/components/shared/icons/arrow_right';
import IconArrowLeft from '/src/components/shared/icons/arrow_left';

import Superlative from '/src/components/end/superlative';
import FinalScores from '/src/components/end/final_scores';
import FinalLikes from '/src/components/end/final_likes';
import NewGame from '/src/components/end/new_game';

function EndContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="cntnr h-1/2 flex flex-col items-center mx-6 my-4 md:my-6 pt-6 px-6 pb-4 md:flex-grow-0 md:w-2/3 lg:w-2/5 md:h-full">
      {children}
    </div>
  );
}

function End() {
  const [step, setStep] = useState(0);
  const { game, gameId } = useGameState();

  const superlatives = useMemo(
    () => game && generateSuperlatives(game, gameId),
    [game, gameId]
  );

  if (!game || !superlatives) return null;

  const views = superlatives.map(superlative => (
    <EndContainer key={superlative.header}>
      <Superlative superlative={superlative} />
    </EndContainer>
  ));

  if (!game.topicPack) {
    views.push(
      <EndContainer key="final-likes">
        <FinalLikes game={game} />
      </EndContainer>
    );
  }

  views.push(
    <EndContainer key="final-scores">
      <FinalScores game={game} />
    </EndContainer>,
    <EndContainer key="new-game">
      <NewGame />
    </EndContainer>
  );

  return (
    <div className="flex flex-col h-full bg-color--primary">
      <div className="mt-4 md:mt-8 md:flex-grow">
        <Logo size={isDesktop() ? 'med' : 'tiny'} />
      </div>
      <SwipeableViews
        containerStyle={{ flexGrow: 1 }}
        index={step}
        onChangeIndex={setStep}
        slideClassName="flex flex-col md:items-center"
        className="flex flex-col flex-grow md:flex-grow-0"
      >
        {views}
      </SwipeableViews>
      <div className="flex justify-between mx-6 mb-6 md:self-center md:w-2/3 lg:w-2/5">
        <div className={cx({ invisible: step === 0 })}>
          <Button
            name="back"
            onClick={() => setStep(prevStep => prevStep - 1)}
            variant="text-invert"
          >
            <div className="flex items-center">
              <IconArrowLeft white />
              Back
            </div>
          </Button>
        </div>
        <div
          className={cx({
            invisible: step === views.length - 1
          })}
        >
          <Button
            name="next"
            onClick={() => setStep(prevStep => prevStep + 1)}
            variant="text-invert"
          >
            <div className="flex items-center">
              Next
              <IconArrowRight white />
            </div>
          </Button>
        </div>
      </div>
      <div className="md:flex-grow" />
    </div>
  );
}

export default End;
