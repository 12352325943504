import React from 'react';

import cx from '/src/utilities/cx';

import { STATE } from '/src/state/types';
import { TURN_STATE } from '/src/utilities/game';

import Footer from '/src/components/game/footer';
import Header from '/src/components/game/header';
import DraggableTopics from '/src/components/game/topics/rankable_topics.draggable';

type PROPS = {
  gameState: STATE;
  turnState: TURN_STATE;
  hideInfo: () => void;
  showInfo: boolean;
  topicsTop: number;
};

function GameTopics({
  gameState,
  turnState,
  hideInfo,
  showInfo,
  topicsTop
}: PROPS) {
  const gameTopicsClasses = cx(
    'game-topics cntnr cntnr--top-rounded flex flex-col items-center px-4',
    {
      'game-topics--hidden pt-2': showInfo,
      'py-6': !showInfo
    }
  );

  return (
    <div
      className={gameTopicsClasses}
      style={{ top: showInfo ? undefined : topicsTop }}
    >
      {turnState && (
        <>
          <Header
            turnState={turnState}
            hideInfo={hideInfo}
            showInfo={showInfo}
          />

          {!showInfo && (
            <>
              <DraggableTopics gameState={gameState} turnState={turnState} />

              <Footer turnState={turnState} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default GameTopics;
