import { ACTION_PARAMS } from '/src/actions/types';
import {
  subscribeToGameUpdatesService,
  subscribeToPlayerRanksService
} from '/src/services';

export function subscribeToGameUpdatesAction(
  gameUid: string,
  playerUid: string,
  { dispatch }: ACTION_PARAMS
) {
  return new Promise<void>(resolve => {
    subscribeToGameUpdatesService(gameUid, game => {
      if (game) {
        dispatch({
          type: 'game_update',
          game
        });

        resolve();
      } else {
        // toRoot()(); TODO
      }
    });

    subscribeToPlayerRanksService(gameUid, playerUid, ranks => {
      dispatch({
        type: 'update_local_ranks',
        ranks
      });
    });
  });
}
