import React from 'react';

import useThemeColor from '/src/components/shared/use_theme_color';
import PROPS from './props';

function IconDice({ white }: PROPS) {
  const themeColor = useThemeColor();

  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5552 16.1686C17.5552 15.8393 17.6873 15.5234 17.9224 15.2905C18.1576 15.0577 18.4766 14.9268 18.8091 14.9268C19.1417 14.9268 19.4606 15.0577 19.6958 15.2905C19.931 15.5234 20.0631 15.8393 20.0631 16.1686C20.0631 16.498 19.931 16.8139 19.6958 17.0467C19.4606 17.2796 19.1417 17.4105 18.8091 17.4105C18.4766 17.4105 18.1576 17.2796 17.9224 17.0467C17.6873 16.8139 17.5552 16.498 17.5552 16.1686Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M15.0244 8.8051C14.7892 8.57222 14.4703 8.44138 14.1377 8.44138C13.8051 8.44138 13.4862 8.57222 13.251 8.8051C13.0159 9.03799 12.8837 9.35385 12.8837 9.6832C12.8837 10.0126 13.0159 10.3284 13.251 10.5613C13.4862 10.7942 13.8051 10.925 14.1377 10.925C14.4703 10.925 14.7892 10.7942 15.0244 10.5613C15.2595 10.3284 15.3916 10.0126 15.3916 9.6832C15.3916 9.35385 15.2595 9.03799 15.0244 8.8051Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M8.89018 13.1239C9.12534 12.891 9.44429 12.7602 9.77686 12.7602C10.1094 12.7602 10.4284 12.891 10.6635 13.1239C10.8987 13.3568 11.0308 13.6726 11.0308 14.002C11.0308 14.3313 10.8987 14.6472 10.6635 14.8801C10.4284 15.113 10.1094 15.2438 9.77686 15.2438C9.44429 15.2438 9.12534 15.113 8.89018 14.8801C8.65502 14.6472 8.52291 14.3313 8.52291 14.002C8.52291 13.6726 8.65502 13.3568 8.89018 13.1239Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M6.27925 8.78166C6.04409 8.54878 5.72515 8.41794 5.39258 8.41794C5.06001 8.41794 4.74106 8.54878 4.5059 8.78166C4.27074 9.01455 4.13863 9.33041 4.13863 9.65976C4.13863 9.98912 4.27074 10.305 4.5059 10.5379C4.74106 10.7708 5.06001 10.9016 5.39258 10.9016C5.72515 10.9016 6.04409 10.7708 6.27925 10.5379C6.51441 10.305 6.64653 9.98912 6.64653 9.65976C6.64653 9.33041 6.51441 9.01455 6.27925 8.78166Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        d="M8.86674 4.46269C9.1019 4.22981 9.42085 4.09897 9.75342 4.09897C10.086 4.09897 10.4049 4.22981 10.6401 4.46269C10.8753 4.69558 11.0074 5.01144 11.0074 5.3408C11.0074 5.67015 10.8753 5.98601 10.6401 6.2189C10.4049 6.45178 10.086 6.58262 9.75342 6.58262C9.42085 6.58262 9.1019 6.45178 8.86674 6.2189C8.63158 5.98601 8.49947 5.67015 8.49947 5.3408C8.49947 5.01144 8.63158 4.69558 8.86674 4.46269Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0834 1.34333C10.7307 0.994004 10.2523 0.797753 9.75342 0.797753C9.25457 0.797753 8.77615 0.994004 8.42341 1.34333L1.33001 8.36813C0.977272 8.71746 0.779104 9.19126 0.779103 9.68529C0.779103 10.1793 0.977272 10.6531 1.33001 11.0024L8.42341 18.0272C8.77615 18.3766 9.25457 18.5728 9.75342 18.5728C10.2523 18.5728 10.7307 18.3766 11.0834 18.0272L18.1768 11.0024C18.5296 10.6531 18.7277 10.1793 18.7277 9.68529C18.7277 9.19126 18.5296 8.71746 18.1768 8.36813L11.0834 1.34333ZM17.2901 9.24623L10.1968 2.22143C10.0792 2.10499 9.9197 2.03957 9.75342 2.03957C9.58713 2.03957 9.42766 2.10499 9.31008 2.22143L2.21669 9.24623C2.09911 9.36268 2.03305 9.52061 2.03305 9.68529C2.03305 9.84996 2.09911 10.0079 2.21669 10.1243L9.31008 17.1491C9.42766 17.2656 9.58713 17.331 9.75342 17.331C9.9197 17.331 10.0792 17.2656 10.1968 17.1491L17.2901 10.1243C17.4077 10.0079 17.4738 9.84996 17.4738 9.68529C17.4738 9.52061 17.4077 9.36268 17.2901 9.24623Z"
        fill={white ? '#fff' : themeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9126 17.2048V21.136C11.9126 21.63 12.1108 22.1038 12.4635 22.4531C12.8162 22.8025 13.2947 22.9987 13.7935 22.9987H23.8251C24.3239 22.9987 24.8024 22.8025 25.1551 22.4531C25.5078 22.1038 25.706 21.63 25.706 21.136V11.2014C25.706 10.7074 25.5078 10.2336 25.1551 9.88427C24.8024 9.53494 24.3239 9.33868 23.8251 9.33868H18.6951C18.7167 9.45175 18.7277 9.56732 18.7277 9.68402C18.7277 10.0003 18.6465 10.3082 18.4956 10.5805H23.8251C23.9914 10.5805 24.1508 10.6459 24.2684 10.7624C24.386 10.8788 24.4521 11.0367 24.4521 11.2014V21.136C24.4521 21.3007 24.386 21.4586 24.2684 21.575C24.1508 21.6915 23.9914 21.7569 23.8251 21.7569H13.7935C13.6272 21.7569 13.4678 21.6915 13.3502 21.575C13.2326 21.4586 13.1665 21.3007 13.1665 21.136V15.963L11.9126 17.2048Z"
        fill={white ? '#fff' : themeColor}
      />
    </svg>
  );
}

export default IconDice;
