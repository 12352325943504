import React from 'react';

import useThemeColor from '/src/components/shared/use_theme_color';
import PROPS from './props';

function IconArrowLeft({ white }: PROPS) {
  const themeColor = useThemeColor();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4306 5.57977C11.3833 5.46589 11.314 5.36245 11.2268 5.27538C11.1397 5.18809 11.0362 5.11883 10.9224 5.07158C10.8085 5.02432 10.6864 5 10.5631 5C10.4398 5 10.3177 5.02432 10.2039 5.07158C10.09 5.11883 9.98654 5.18809 9.89947 5.27538L4.27538 10.8995C4.18809 10.9865 4.11883 11.09 4.07158 11.2039C4.02432 11.3177 4 11.4398 4 11.5631C4 11.6864 4.02432 11.8085 4.07158 11.9224C4.11883 12.0362 4.18809 12.1397 4.27538 12.2268L9.89947 17.8508C9.98662 17.938 10.0901 18.0071 10.204 18.0543C10.3178 18.1015 10.4399 18.1257 10.5631 18.1257C10.6864 18.1257 10.8084 18.1015 10.9223 18.0543C11.0361 18.0071 11.1396 17.938 11.2268 17.8508C11.3139 17.7637 11.383 17.6602 11.4302 17.5464C11.4774 17.4325 11.5016 17.3105 11.5016 17.1872C11.5016 17.064 11.4774 16.9419 11.4302 16.828C11.383 16.7142 11.3139 16.6107 11.2268 16.5236L7.20204 12.5004H18.0628C18.3114 12.5004 18.5498 12.4016 18.7256 12.2258C18.9014 12.05 19.0002 11.8116 19.0002 11.563C19.0002 11.3144 18.9014 11.076 18.7256 10.9002C18.5498 10.7244 18.3114 10.6257 18.0628 10.6257H7.20223L11.2268 6.60267C11.314 6.51559 11.3833 6.41216 11.4306 6.29828C11.4778 6.1844 11.5021 6.06232 11.5021 5.93902C11.5021 5.81573 11.4778 5.69365 11.4306 5.57977Z"
        fill={white ? '#fff' : themeColor}
      />
    </svg>
  );
}

export default IconArrowLeft;
