import React from 'react';

import { GAME } from '/src/state/types';

import { toAllPlayersWithLikes } from '/src/utilities/players';

import IconFrowneySmall from '/src/components/shared/icons/frowney_small';
import IconSmileySmall from '/src/components/shared/icons/smiley_small';

type PROPS = {
  game: GAME;
};

function FinalLikes({ game: { likes = {}, players, topics = {} } }: PROPS) {
  const playerLikes = toAllPlayersWithLikes(likes, players, topics);

  return (
    <div className="flex-grow w-full flex flex-col justify-between items-center overflow-hidden">
      <h1 className="modal-header color--dark-gray mb-6">Popularity Contest</h1>
      <div className="flex-grow w-full overflow-y-auto px-2">
        {playerLikes &&
          playerLikes.map(({ name, numLikes }) => (
            <div
              key={name}
              className="flex justify-between items-center font-bold text-lg h-8"
            >
              <span className="color--primary-dark">{name}</span>
              <div className="flex items-center">
                <span className="mr-2">{numLikes}</span>
                {numLikes === 0 ? <IconFrowneySmall /> : <IconSmileySmall />}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default FinalLikes;
