import { GAME_STATE } from '/src/utilities/constants';
import { GUESSES_OBJ, TOPIC, TOPIC_OBJ, TOPIC_PACK } from '/src/state/types';

export function toTopicsArray(topics: TOPIC_OBJ | undefined) {
  if (!topics) return [];

  return Object.keys(topics).map(uid => ({ uid, ...topics[uid] }));
}

export function toPlayerTopics(
  topics: TOPIC_OBJ | undefined,
  playerUid: string
) {
  return toTopicsArray(topics).filter(
    topic => topic.playerUid === playerUid && topic.status === 'available'
  );
}

export function toActiveTopics(topics: TOPIC_OBJ | undefined) {
  return toTopicsArray(topics).filter(
    ({ status }) => status === 'active' || status === 'ranked'
  );
}

export function toGuessesByTopic(guesses: GUESSES_OBJ) {
  return Object.keys(guesses)
    .map(playerUid => ({ ...guesses[playerUid] }))
    .reduce<{ [key: string]: number[] }>((topicGuesses, playerGuesses) => {
      Object.keys(playerGuesses).forEach(topicUid => {
        const playerGuess = playerGuesses[topicUid];

        if (typeof playerGuess === 'string') return;

        topicGuesses[topicUid] = [
          ...(topicGuesses[topicUid] || []),
          playerGuess
        ];
      });

      return topicGuesses;
    }, {});
}

export function rankableTopicState(
  activeTopics: TOPIC[],
  guesses: { [key: string]: number[] },
  isRanker: boolean,
  localRank: number,
  topic: TOPIC,
  turnState: string
) {
  const correctTopic = activeTopics.find(
    ({ rank, status }) => rank === localRank && status === 'ranked'
  );
  const isRanked = !!correctTopic;
  const isCorrect = isRanked && topic.uid === correctTopic.uid;
  const totalGuesses = (isRanked && guesses[correctTopic.uid || '']) || [];
  const correctGuesses =
    (isRanked && totalGuesses.filter(guess => guess === correctTopic.rank)) ||
    [];
  const correctPercent = totalGuesses.length
    ? (isRanked &&
        `${Math.round(
          (correctGuesses.length / totalGuesses.length) * 100
        )}%`) ||
      ''
    : '100%';

  const isRevealable =
    turnState === GAME_STATE.LOCKED_IN && isRanker && topic.status !== 'ranked';

  return { correctTopic, isRanked, isCorrect, isRevealable, correctPercent };
}

function sortPacksByName(packs: TOPIC_PACK[]) {
  return packs.sort((packA, packB) => packA.name.localeCompare(packB.name));
}

export function groupTopicPacks(
  topicPacks: TOPIC_PACK[],
  topicPackOwner?: string
) {
  const { custom, Seasonal, Standard, Expansion, Specialty, ...other } =
    topicPacks.reduce<{
      [key: string]: TOPIC_PACK[];
    }>((groups, topicPack) => {
      if (
        !topicPack.live ||
        topicPack.category === 'example' ||
        (topicPack.owner && topicPack.owner !== topicPackOwner)
      ) {
        return groups;
      }

      const category = groups[topicPack.category] || [];

      return { ...groups, [topicPack.category]: [...category, topicPack] };
    }, {});

  const otherCategories = Object.keys(other)
    .sort()
    .map(category => ({
      name: category,
      packs: other[category]
    }));

  return [
    custom && { name: 'My packs', packs: sortPacksByName(custom) },
    Seasonal && { name: 'Seasonal', packs: sortPacksByName(Seasonal) },
    Standard && { name: 'Classic', packs: sortPacksByName(Standard) },
    Expansion && { name: 'Expansion', packs: sortPacksByName(Expansion) },
    Specialty && { name: 'Limited Edition', packs: sortPacksByName(Specialty) },
    ...otherCategories
  ].filter(pack => !!pack);
}
