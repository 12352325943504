import React from 'react';

import { colors } from '/src/components/shared/use_theme_color';

function IconSmallCheck() {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6646 1.53075C17.6899 0.519362 15.4684 0 13 0C10.5316 0 8.2827 0.519362 6.30802 1.53075C4.33333 2.56948 2.79747 3.99089 1.673 5.82232C0.548525 7.65376 0 9.70387 0 12C0 14.2961 0.548525 16.3462 1.673 18.1777C2.79747 20.0091 4.33333 21.4305 6.30802 22.4692C8.2827 23.508 10.5042 24 13 24C15.4958 24 17.7173 23.4806 19.692 22.4692C21.6667 21.4305 23.2025 20.0091 24.327 18.1777C25.4515 16.3462 26 14.2961 26 12C26 9.70387 25.4515 7.65376 24.327 5.82232C23.2025 3.99089 21.6392 2.56948 19.6646 1.53075ZM10.5042 18.123L10.4494 18.0683L8.58439 16.2096L5.10127 12.738L6.99367 10.8519L10.4768 14.3235L19.1983 5.63098L21.0907 7.51708L10.5042 18.123Z"
        fill={colors.COLOR_GREEN}
      />
    </svg>
  );
}

export default IconSmallCheck;
