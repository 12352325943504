import React, { useState } from 'react';

import { randomizeThemeAction } from '/src/actions';
import { useAction, useGameState } from '/src/state';
import Button from '/src/components/shared/button';
import useRouter from '/src/utilities/router';

type PROPS = {
  gameId: string | undefined;
};

function Share({ gameId }: PROPS) {
  const [shareError, setShareError] = useState(false);

  const randomizeTheme = useAction(randomizeThemeAction);
  const { toAddTopics, toPlayers } = useRouter();

  const { game } = useGameState();
  const { topicPack } = game || {};

  const canShare = (window.navigator as any).share;

  return (
    <div className="flex flex-grow flex-col items-center">
      <h1 className="modal-header color--dark-gray mb-8">Success!</h1>
      <h2 className="modal-header mb-4">Your Game ID is</h2>
      <span
        className="font-bold color--primary text-6xl mb-9"
        data-cy="share-game-id"
      >
        {gameId}
      </span>
      <p className="text-center mx-8 text-lg">
        Share this ID with your friends so they can join your game
      </p>
      <div className="flex-grow" />
      <div className="my-6 w-3/4">
        {canShare && (
          <div className="mb-4">
            <Button
              disabled={shareError}
              fullWidth
              name="share"
              onClick={() => {
                window.navigator
                  .share({
                    title: 'Top4',
                    text: 'Join my Top Four game!',
                    url: `${window.location.origin}/join?gameId=${gameId}`
                  })
                  .catch((err: DOMException) => {
                    if (err.name === 'AbortError') return;

                    setShareError(true);
                  });
              }}
              variant="secondary"
            >
              {shareError ? 'Unable to share' : 'Share'}
            </Button>
          </div>
        )}
        {topicPack && (
          <Button
            fullWidth
            name="share-done"
            onClick={() => {
              toPlayers();
              randomizeTheme();
            }}
            variant="primary"
          >
            Done
          </Button>
        )}
        {!topicPack && (
          <Button
            fullWidth
            name="share-topics"
            onClick={() => {
              toAddTopics();
              randomizeTheme();
            }}
            variant="primary"
          >
            Add Topics
          </Button>
        )}
      </div>
    </div>
  );
}

export default Share;
