import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import isValid from 'date-fns/isValid';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import cx from '/src/utilities/cx';
import { isDesktop } from '/src/utilities/device';
import useRouter, { ROUTES } from '/src/utilities/router';
import {
  clearLocalGameState,
  getLocalGameState
} from '/src/utilities/local_storage';

import { useAction } from '/src/state';
import { openModalAction } from '/src/actions';
import { joinGameAction, setTopicPackOwnerAction } from '/src/actions/pre_game';

import Coachmark from '/src/components/home/coachmark';
import Button from '/src/components/shared/button';
import Logo from '/src/components/shared/logo';
import IconCircleI from '/src/components/shared/icons/circle_i';
import WhatsNew from '/src/components/whats_new';
import useWhatsNew from '/src/components/whats_new/use_whats_new';

import './style.scss';

function renderRejoinButton(
  rejoinGame: Function,
  routes: ROUTES,
  loading: boolean,
  setLoading: (loading: boolean) => void
) {
  const { gameId, gameStartDate, playerName } = getLocalGameState();

  if (!gameId || !gameStartDate || !playerName) return null;

  const startDate = new Date(gameStartDate);

  if (isValid(startDate) && differenceInMinutes(new Date(), startDate) <= 120) {
    return (
      <div className="mb-5">
        <Button
          disabled={loading}
          fullWidth
          name="rejoin-game"
          onClick={() => {
            setLoading(true);
            rejoinGame(gameId, playerName, true, routes);
          }}
          variant="secondary-invert"
        >
          REJOIN {gameId}
        </Button>
      </div>
    );
  }
}

function Home() {
  const [anim, setAnim] = useState(false);
  const [loading, setLoading] = useState(false);
  const routes = useRouter();
  const { search } = useLocation();

  const openModal = useAction(openModalAction);
  const setTopicPackOwner = useAction(setTopicPackOwnerAction);
  const rejoinGame = useAction(joinGameAction);

  const owner = new URLSearchParams(search).get('owner');

  const whatsNew = useWhatsNew({ delay: 1000 });

  useEffect(() => {
    setTimeout(() => setAnim(true), 500);

    const { gameStartDate } = getLocalGameState();
    const startDate = new Date(gameStartDate || '');

    if (
      isValid(startDate) &&
      differenceInMinutes(new Date(), startDate) > 720
    ) {
      clearLocalGameState();
    }
  }, []);

  useEffect(() => {
    setTopicPackOwner(owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);

  function handleStartGame() {
    clearLocalGameState();
    routes.toCreate();
  }

  const logoClasses = cx('home__logo', { 'home__logo--anim': anim });
  const actionClasses = cx('home__actions mb-5 w-3/5 md:w-1/4 lg:w-1/5', {
    'home__actions--anim': anim
  });
  const aboutClasses = cx('home__about', {
    'home__about--anim': whatsNew
  });

  return (
    <div className="home h-full bg-color--primary">
      {whatsNew && (
        <div data-testid={'about-button'} className={aboutClasses}>
          <Button
            name="about"
            onClick={() => openModal(<WhatsNew whatsNew={whatsNew} />)}
            variant="icon"
          >
            <IconCircleI white />
          </Button>
        </div>
      )}
      <div className={logoClasses}>
        <Logo size={isDesktop() ? 'large' : 'med'} />
      </div>
      <div className={actionClasses}>
        {renderRejoinButton(rejoinGame, routes, loading, setLoading)}
        <div className="mb-5">
          <Button
            fullWidth
            name="join-game"
            onClick={routes.toJoin}
            variant="primary-invert"
          >
            JOIN A GAME
          </Button>
        </div>
        <div className="mb-5">
          <Button
            fullWidth
            name="start-game"
            onClick={handleStartGame}
            variant="primary-invert"
          >
            START A GAME
          </Button>
        </div>
        <div className="mb-5 pt-5">
          <Button
            fullWidth
            name="home-instructions"
            onClick={() => openModal(<Coachmark />)}
            variant="secondary-invert"
          >
            HOW TO PLAY
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Home;
