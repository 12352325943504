import React from 'react';

import IconSmallCheck from '/src/components/shared/icons/small_check';
import IconSmallX from '/src/components/shared/icons/small_x';

type PROPS = {
  correctPercent: string;
  isCorrect: boolean;
  isRanker: boolean;
  showPercent: boolean;
};

function TopicStatus({
  correctPercent,
  isCorrect,
  isRanker,
  showPercent
}: PROPS) {
  if (isRanker && showPercent) {
    return (
      <div className="mr-4 flex flex-col justify-between items-center">
        <span className="color--primary font-bold">{correctPercent}</span>
      </div>
    );
  }

  if (!isRanker && showPercent) {
    return (
      <div className="mr-4 flex flex-col justify-between items-center">
        {isCorrect && <IconSmallCheck />}
        {!isCorrect && <IconSmallX />}
        <span className="font-bold mt-1">{correctPercent}</span>
      </div>
    );
  }

  return null;
}

export default TopicStatus;
