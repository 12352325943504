import React from 'react';

import { GAME } from '/src/state/types';

import { toAllPlayersWithScores } from '/src/utilities/players';

type PROPS = {
  game: GAME;
};

function FinalScores({ game }: PROPS) {
  const playerScores = toAllPlayersWithScores(game).filter(
    ({ active }) => active
  );

  const anyLateJoiners = playerScores.some(({ lateJoiner }) => lateJoiner);

  return (
    <div className="flex-grow w-full flex flex-col justify-between items-center overflow-hidden">
      <h1 className="modal-header color--dark-gray mb-6">Final Scores</h1>
      <div className="flex flex-col flex-grow w-full overflow-y-auto px-2">
        {playerScores &&
          playerScores
            .filter(({ active }) => active)
            .map(({ name, score, lateJoiner }, index) => (
              <div
                key={name}
                className="flex justify-between items-center font-bold text-lg h-8"
              >
                <span>
                  <span className="color--primary inline-block mr-4">
                    {index + 1}.
                  </span>
                  <span>
                    {name}
                    {lateJoiner ? '*' : ''}
                  </span>
                </span>
                <span>{score}</span>
              </div>
            ))}
        {anyLateJoiners && (
          <div className="flex flex-col justify-end items-center flex-grow mt-4">
            <span className="italic">*the Late Joiner advantage</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default FinalScores;
